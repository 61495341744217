import { Link } from "react-router-dom"

export const ButtonBlue = ({text, to, params}) => {
    return(
        <Link to={params ? `${to}?${params}` : to} className="bg-primary text-white py-1 px-4 rounded-lg hover:bg-opacity-80 transition-all duration-300">{text}</Link>
    )
}

export const ButtonRedBig = ({text, onClick, disabled}) => {
    return(
        <button disabled={disabled} className={`${disabled ? "bg-gray-500" : "bg-highligth "} text-white py-2 px-11 rounded-full hover:bg-opacity-80 transition-all duration-300 `} onClick={onClick}>{text}</button>
    )
}

export const ButtonBorderRed = ({text, onClick}) => {
    return(
        <button className="bg-white border border-highligth text-highligth hover:bg-hoverhighligth disabled:bg-gray-500 py-2 px-11 rounded-full hover:bg-opacity-80 transition-all duration-300" onClick={onClick}>{text}</button>
    )
}

export const ButtonBlueBig = ({text, onClick}) => {
    return(
        <button className="bg-primary text-white py-2 px-11 rounded-full hover:bg-opacity-80 transition-all duration-300" onClick={onClick}>{text}</button>
    )
}