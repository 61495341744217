import { useEffect, useState } from "react"
import { getOwnerProfile } from "../../../../services/User";
import { useAuth } from "../../../../context/AuthContext";
import { useParams } from "react-router-dom";
import { useAxiosWithLoading } from "../../../../services/config/config";


const OwnerInformation = () => {

  const {owner_id} = useParams()
  const {token} = useAuth()
  const axiosInstance = useAxiosWithLoading();

  const [owner, setOwner] = useState({})
  const fetchOwner = async () => {
    const fetchedOwner = await getOwnerProfile({ token, id: owner_id, axiosInstance });
    setOwner(fetchedOwner)
  };
  useEffect(() => {
      fetchOwner()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-1 flex-col gap-10">
      <div className="flex flex-row justify-between items-center flex-wrap gap-8">
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Numero de cuenta</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{owner?.user?.id}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Nombre</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{owner?.user?.name}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Apellido</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{owner?.user?.lastname}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Email/s</h4>
          {
            owner?.contact?.map((item,i) => {
              return (
                item?.type === "email" && <div key={i} className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                  <p>{item?.value}</p>
                </div>
              )
            })
          }
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Teléfono/s</h4>
          {
            owner?.contact?.map((item,i) => {
              return (
                item?.type === "phone" && <div key={i} className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                  <p>{item?.value}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default OwnerInformation;