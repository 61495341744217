import { useEffect, useState } from "react";
import Lists from "../../components/Lists";
import { FilterTabs } from "../../components/OptionsTabs";
import { allCloseTickets, allOpenTickets, allTickets } from "../../services/Tickets";
import { useAuth } from "../../context/AuthContext";
import { useAxiosWithLoading } from "../../services/config/config";

const Tickets = () => {

    const {token} = useAuth()
    const [tickets, setTickets] = useState([])
    const [ticketsActive, setTicketsActive] = useState([])
    const [ticketsClosed, setTicketsClosed] = useState([])
    const [selected, setSelected] = useState("all")
    const tabs = [
        {text: "Todos", value: "all"},
        {text: "Activos", value: "active"},
        {text: "Cerrados", value: "closed"},
    ]
    const categies = [ "Título", "Numero de cuenta", "Fecha", "Estado", "Gestionar"]
    const keys = ['description', 'user_id', 'createdAt', 'status'];
    const axiosInstance = useAxiosWithLoading();

    const fetchTickets = async () => {
        const fetchedTickets = await allTickets({ token, axiosInstance });
        const fetchedTicketsOpen = await allOpenTickets({ token, axiosInstance });
        const fetchedTicketsClosed = await allCloseTickets({ token, axiosInstance });
        Array.isArray(fetchedTickets) ? setTickets(fetchedTickets) : setTickets([])
        Array.isArray(fetchedTicketsOpen) ? setTicketsActive(fetchedTicketsOpen) : setTicketsActive([])
        Array.isArray(fetchedTicketsClosed) ? setTicketsClosed(fetchedTicketsClosed) : setTicketsClosed([])
    };
    useEffect(() => {
        fetchTickets()
        // eslint-disable-next-line
    },[])

    return (
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">Tickets</p>
            <FilterTabs tabs={tabs} selected={selected} setSelected={setSelected} />
            <Lists categies={categies} keys={keys} items={selected === "active" ? ticketsActive : selected === "closed" ? ticketsClosed : tickets} buttonText={"Acceder"} />
        </div>
    )
}

export default Tickets;