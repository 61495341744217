import { useNavigate, useParams } from "react-router-dom";
import { ButtonRedBig } from "../../../../components/Buttons";
import Lists from "../../../../components/Lists";
import SearchBar from "../../../../components/SearchBar";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import { getReportsByCommunity } from "../../../../services/Reports";
import { useAxiosWithLoading } from "../../../../services/config/config";

const RecordReports = () => {

    const {token} = useAuth()
    const { community_id } = useParams();
    const [reports, setReports] = useState([])
    const categies = [ "Nombre", "Fecha", "Gestionar"]
    const keys = ['name_minutes', 'createdAt'];
    const [searchResults, setSearchResults] = useState([]);
    const axiosInstance = useAxiosWithLoading();

    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };

    const fetchReports = async () => {
        const fetchedReports = await getReportsByCommunity({ token, id: community_id, axiosInstance });
        Array.isArray(fetchedReports) ? setReports(fetchedReports) : setReports([])
        Array.isArray(fetchedReports) ? setSearchResults(fetchedReports) : setSearchResults([])
    };

    useEffect(() => {
        fetchReports()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
                <SearchBar
                    placeholder="Buscar Acta o Informe"
                    data={reports}
                    searchParams={['name_minutes']}
                    onResults={setSearchResults}
                />
                <ButtonRedBig text={"Cargar Nueva Acta o Informe"} onClick={() => handleClick("./new-record-report")} />
            </div>
            <Lists categies={categies} keys={keys} items={searchResults} path={"record-reports"} buttonText={"Gestionar"} />
        </div>
    )
}

export default RecordReports;