import React, { useEffect, useState } from 'react';
import { useAuth } from "../../../context/AuthContext";
import { useParams } from 'react-router-dom';
import { getRealStateById } from '../../../services/RealState';
import { useAxiosWithLoading } from '../../../services/config/config';

const RealStateInfo = () => {

    const [realState, setRealState] = useState({});
    const { token } = useAuth();
    const axiosInstance = useAxiosWithLoading();

    const fetchRealState = async () => {
        const fetchedRealState = await getRealStateById({ token, id: property_id, axiosInstance });
        setRealState(fetchedRealState)
    };

    useEffect(() => {
        fetchRealState()
        // eslint-disable-next-line
    },[])


    const { property_id } = useParams()

    const InputView = ({title, text}) => {
        return(
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">{title}</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{text}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">{realState?.property_name}</p>
            <div className="flex flex-row flex-wrap gap-8">
                {
                    realState?.gallery?.map((url, i) => (
                        <div key={i} className="relative w-60 h-36 bg-sidebar">
                            <img src={url?.rest?.image} alt={`imagen-${i}`} className="w-full h-full object-cover" />
                        </div>
                    ))
                }
            </div>
            <div className="flex flex-row justify-around flex-wrap gap-x-[2%] gap-y-8">
                <InputView title={"Precio"} text={realState?.price} />
                <InputView title={"Tipo de vivienda"} text={realState?.characteristics?.property_type} />
                <InputView title={"Descripción"} text={realState?.characteristics?.description} />
                <InputView title={"Dirección"} text={realState?.address} />
                <InputView title={"Provincia"} text={realState?.province} />
                <InputView title={"Localidad"} text={realState?.locality} />
                <InputView title={"Metros cuadrados"} text={realState?.characteristics?.square_meter} />
                <InputView title={"Piso"} text={realState?.floor} />
                <InputView title={"Numero de plantas (Pisos)"} text={realState?.characteristics?.floors_number} />
                <InputView title={"Numero de habitaciones"} text={realState?.characteristics?.rooms_number} />
                <InputView title={"Baños"} text={realState?.characteristics?.bathrooms_number} />
                <InputView title={"Teléfono de contacto"} text={realState?.phone} />
                <div className="flex flex-row items-center w-[48%] gap-[10%]">
                    <label className='flex flex-row gap-2 items-center text-base font-semibold'>
                        Compra
                        <input type="radio" value="COMPRA" checked={realState?.rental === 'COMPRA'} disabled />
                    </label>
                    <label className='flex flex-row gap-2 items-center text-base font-semibold'>
                        Alquiler
                        <input type="radio" value="ALQUILER" checked={realState?.rental === 'ALQUILER'} disabled />
                    </label>
                </div>
                <div className='flex flex-row w-[48%] items-center gap-[10%]'>
                    <div className="flex flex-row gap-4 items-center w-[10%]">
                        <label className="text-base font-semibold">Piscina</label>
                        <input type="checkbox" checked={realState?.characteristics?.parking} disabled />
                    </div>
                    <div className="flex flex-row gap-4 items-center w-[10%]">
                        <label className="text-base font-semibold">Estacionamiento</label>
                        <input type="checkbox" checked={realState?.characteristics?.swimmingpool} disabled />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RealStateInfo;