import { ListsNoButton } from "../../../../components/Lists";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { useParams } from "react-router-dom";
import { getOwnerProperties } from "../../../../services/User";
import { useAxiosWithLoading } from "../../../../services/config/config";

const OwnerProperties = () => {
    
  const categies = [ "Número de finca", "Direccion", "Cuenta bancaria",  "Representante"]
  const keys = ['fincaNum', 'address', 'bank_account', 'name_accounting_account'];
  const {owner_id} = useParams()
  const {token} = useAuth()
  const [properties, setProperties] = useState([]);
  const axiosInstance = useAxiosWithLoading();

  const fetchProperties = async () => {
    const fetchedProperties = await getOwnerProperties({ token, id: owner_id, axiosInstance});
    Array.isArray(fetchedProperties) ? setProperties(fetchedProperties) : setProperties([])
  };

  useEffect(() => {
      fetchProperties()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-1 flex-col gap-10">
      <ListsNoButton categies={categies} keys={keys} items={properties} />
    </div>
  )
}

export default OwnerProperties;