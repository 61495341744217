export const InputDefault = () => {
    return(
        <div>

        </div>
    )
}

export const InputTitle = ({title, value, onChange, placeholder, disabled, type}) => {
    return(
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
            <h4 className="text-base font-semibold">{title}</h4>
            <input type={type} placeholder={placeholder} disabled={disabled} onChange={onChange} value={value} className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" />
        </div>
    )
}


export const InputTitleMultiple = ({ title, values = [], onValuesChange, type }) => {
    const handleChange = (index, event) => {
        const newValues = [...values];
        newValues[index] = event.target.value;
        onValuesChange(newValues);
    };

    const handleAddValue = () => {
        onValuesChange([...values, '']);
    };

    const handleRemoveValue = (index) => {
        const newValues = values.filter((_, i) => i !== index);
        onValuesChange(newValues);
    };

    return (
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
            <h4 className="text-base font-semibold">{title}</h4>
            {values.map((value, index) => (
                <div key={index} className="flex items-center gap-2">
                    <input type={type} onChange={(e) => handleChange(index, e)} value={value} className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" />
                    {values.length > 1 && (
                        <button type="button" onClick={() => handleRemoveValue(index)} className="text-highligth">Remover</button>
                    )}
                </div>
            ))}
            <button type="button" onClick={handleAddValue} className="mt-2 px-4 py-2 bg-primary text-white rounded-lg" >Agregar</button>
        </div>
    );
};


export const InputTitleFull = ({title, value, onChange, placeholder, disabled, type}) => {
    return(
        <div className="flex flex-col gap-4 w-full">
            <h4 className="text-base font-semibold">{title}</h4>
            <input placeholder={placeholder} disabled={disabled} type={type} onChange={onChange} value={value} className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" />
        </div>
    )
}