export const addDotsNumber = (number) => {
    let numberStr = number.toString();
    let result = '';
    for (let i = numberStr.length - 1, j = 1; i >= 0; i--, j++) {
        result = numberStr[i] + result;
        if (j % 3 === 0 && i !== 0) {
            result = '.' + result;
        }
    }
    return result;
}