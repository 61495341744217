import { useState } from 'react';
import { coreAdapter } from "../adapters/core-adapter";

const SearchBar = ({ placeholder, data, searchParams, onResults }) => {
    const { search } = coreAdapter();
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        const results = searchInData(value);
        onResults(results);
    };

    const searchInData = (value) => {
        return data.filter(item => {
            return searchParams.some(param => {
                return item[param]?.toString().toLowerCase().includes(value.toLowerCase());
            });
        });
    };

    return (
        <div className="flex flex-row border-2 border-black rounded-full gap-2 w-fit px-4 py-1 h-fit">
            <input
                placeholder={placeholder}
                className="w-64 outline-none"
                value={inputValue}
                onChange={handleInputChange}
            />
            <img src={search} alt="search" />
        </div>
    );
};

export default SearchBar;
