import { Link } from "react-router-dom";
import { proffessionAdapter } from "../../../adapters/profession-adapter";


const Professions = () => {

    // eslint-disable-next-line
    const { electricista, tecnicosAC, pintor, cerrajero, fontanero, carpintero, albañil, tecnicoE, jardinero, seguridad, plagas } = proffessionAdapter()

    const optionList = [
        {text: "Albañiles", icon: albañil, route: "ALBANIL"},
        {text: "Carpinteros", icon: carpintero, route: "CARPINTERO"},
        {text: "Cerrajeros", icon: cerrajero, route: "CERRAJEROS"},
        {text: "Control de Plagas", icon: plagas, route: "CONTROL_PLAGAS"},
        {text: "Electricistas", icon: electricista, route: "ELECTRICISTA"},
        {text: "Fontaneros y Desatascador", icon: fontanero, route: "FONTANERO_DESATASCADOR"},
        {text: "Jardineros", icon: jardinero, route: "JARDINERO"},
        {text: "Pintores", icon: pintor, route: "PINTOR"},
        {text: "Sistemas de Seguridad", icon: seguridad, route: "SISTEMA_SEGURIDAD"},
        {text: "Técnicos de Aire Acondicionado", icon: tecnicosAC, route: "TECNICO_AIRE"},
        {text: "Técnicos de Electrodomésticos", icon: tecnicoE, route: "TECNICO_ELEC"},
    ]

    return(
        <div className="flex flex-row flex-wrap gap-8 w-full">
            {
                optionList.map((item, i) => {
                    return(
                        <Link key={i} to={`./${item.route}`} className="flex flex-row gap-8 items-center px-4 py-2 border-2 border-black rounded-lg w-fit min-w-96 hover:bg-hover transition-all duration-300">
                            <img src={item.icon} alt="svg" />
                            <p>{item.text}</p>
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default Professions;