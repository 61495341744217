import { useEffect, useState } from "react";
import { ButtonBlueBig, ButtonRedBig } from "../../../../components/Buttons";
import { ListsOwner } from "../../../../components/Lists";
import SearchBar from "../../../../components/SearchBar";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import OptionsTabs from "../../../../components/OptionsTabs";
import { useAuth } from "../../../../context/AuthContext";
import { listAllArchiveOwners, listOwnersCommunity } from "../../../../services/Communities";
import { getOwnerProfile } from "../../../../services/User";
import { useAxiosWithLoading } from "../../../../services/config/config";

const Owners = () => {

    const {community_id} = useParams()
    const {token} = useAuth()
    const [archived, setArchived] = useState(false)
    const [owners, setOwners] = useState([])
    const [ownersArchived, setOwnersArchived] = useState([])
    const [searchResults, setSearchResults] = useState([]);
    const categies = [ "Propietario", "Numero de Cuenta", "Finca", "Ultima actualización", "Gestionar"]
    const keys = ['name', 'id', 'owner.fincaNum', 'updatedAt'];
    const axiosInstance = useAxiosWithLoading();

    const fetchOwners = async () => {
        const fetchedOwners = await listOwnersCommunity({ token, id: community_id, axiosInstance });
        const fetchedOwnersArchived = await listAllArchiveOwners({ token, id: community_id, axiosInstance });
        !archived ? setSearchResults(fetchedOwners) : setSearchResults(fetchedOwnersArchived)
        !archived ? setOwners(fetchedOwners) : setOwnersArchived(fetchedOwnersArchived)

    };

    useEffect(() => {
        fetchOwners()
        // eslint-disable-next-line
    },[archived])

    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };

    return(
        <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
                <SearchBar
                    placeholder="Buscar Propietario"
                    data={!archived ? owners : ownersArchived}
                    searchParams={['id', 'name', 'lastname']}
                    onResults={setSearchResults}
                />
                <div className="flex flex-row gap-8">
                    <ButtonBlueBig text={archived ? "Ver Propietarios" : "Ver Archivados"} onClick={() => setArchived(!archived)} />
                    <ButtonRedBig text={"Nuevo Propietario"}  onClick={() => handleClick("./new-owner")}  />
                </div>
            </div>
            <ListsOwner categies={categies} keys={keys} items={searchResults} path={"owners"} buttonText={"Acceder"} archived={archived} />
        </div>
    )
}

export default Owners;


export const OwnerInfo = () => {
    
    const { owner_id } = useParams();
    const tabs = [
        {text: "Información", route: owner_id, path: ""},
        {text: "Tickets", route: `tickets`, path: "tickets"},
        {text: "Recibos", route: `receipts`, path: "receipts"},
        {text: "Extractos", route: `extracts`, path: "extracts"},
        {text: "Propiedades", route: `properties`, path: "properties"},
        {text: "Configuración", route: `settings`, path: "settings"},
    ]
    const {token} = useAuth()
    const [owner, setOwner] = useState({})
    const axiosInstance = useAxiosWithLoading();
    const fetchOwner = async () => {
        const fetchedOwner = await getOwnerProfile({ token, id: owner_id, axiosInstance });
        setOwner(fetchedOwner)
    };
    useEffect(() => {
        fetchOwner()
        // eslint-disable-next-line
    },[])

    const location = useLocation();
    const hideForSubOwners = /\/tickets\/(?!$)/.test(location.pathname) || 
    /\/extracts\/(?!$)/.test(location.pathname) ||
    /\/receipts\/(?!$)/.test(location.pathname)

    return (
        <div className="flex flex-col gap-10">
            {!hideForSubOwners && (
                <div className="flex flex-col gap-10">
                    <p className="text-2xl font-medium">{owner?.user?.name} {owner?.user?.lastname}</p>
                    <OptionsTabs tabs={tabs} id={owner_id} />
                </div>
            )}
            <Outlet />
        </div>
    )
}