import { useEffect, useState } from "react"
import { ButtonRedBig } from "../../../../components/Buttons"
import { InputTitle, InputTitleMultiple } from "../../../../components/Inputs"
import { useFieldInputs } from "../../../../hooks/useField"
import { updateUser } from "../../../../services/User"
import { useAuth } from "../../../../context/AuthContext"
import { useNavigate, useParams } from "react-router-dom"
import { useAxiosWithLoading } from "../../../../services/config/config"

const OwnerSettings = () => {

  const axiosInstance = useAxiosWithLoading();
  const { owner_id } = useParams()
  const { token } = useAuth()
  const navigate = useNavigate()

  const name = useFieldInputs({ title: "Nombre" })
  const lastname = useFieldInputs({ title: "Apellido" })
  const [emails, setEmails] = useState(['']);
  const [phones, setPhones] = useState(['']);
  const password = useFieldInputs({ title: "Contraseña Actual o Nueva" })
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState("")

  const handleDisabled = () => {
    if (password.value) {
      setDisabled(false)
      return
    } else {
      setDisabled(true)
      return
    }
  }

  useEffect(() => {
    handleDisabled()
    // eslint-disable-next-line
  }, [name.value, lastname.value, emails, phones, password.value])

  const passwordRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[0-9])/;
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/;

  const handleSubmit = () => {
    if (!passwordRegex.test(password.value)) {
      setError('La contraseña debe poseer mayusculas, minusculas, numeros y mas de 8 caracteres');
    } else if (!emails.map((item, i) => !emailRegex.test(item))) {
      setError('El correo electrónico no cumple con los requisitos.');
    } else {
      updateUser({
        data: {
          "name": name.value,
          "lastname": lastname.value,
          "emails": emails,
          "phones": phones,
          "password": password.value
        },
        token,
        navigate,
        axiosInstance,
        user_id: owner_id,
        setError
      })
    }

  }

  return (
    <div className="flex flex-1 flex-col gap-10">
      <div className="flex flex-row justify-between flex-wrap gap-8">
        <InputTitle {...name} />
        <InputTitle {...lastname} />
        <InputTitleMultiple
          title="Emails"
          values={emails}
          onValuesChange={setEmails}
          type="email"
        />
        <InputTitleMultiple
          title="Teléfonos"
          values={phones}
          onValuesChange={setPhones}
        />
        <InputTitle {...password} />
      </div>
      {
        error && <p className="text-center text-red-500 text-sm">{error}</p>
      }
      <ButtonRedBig text={"Guardar"} disabled={disabled} onClick={handleSubmit} />
    </div>
  )
}

export default OwnerSettings;