import { useEffect, useState } from "react"
import { getProfessionalById } from "../../../services/Professionals"
import { useAuth } from "../../../context/AuthContext"
import { useParams } from "react-router-dom"
import { useAxiosWithLoading } from "../../../services/config/config"

const ManageProfessional = ({Profession}) => {

    const {token} = useAuth()
    const {professional_id} = useParams()
    const [searchResults, setSearchResults] = useState([]);
    const axiosInstance = useAxiosWithLoading();

    const fetchProfessional = async () => {
        const fetchedProfessional = await getProfessionalById({ token, id: professional_id, axiosInstance });
        setSearchResults(fetchedProfessional)
    };
    useEffect(() => {
        fetchProfessional()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-2">
                <p className="text-2xl font-medium">Información del profesional</p>
                <p className="text-base font-medium">{Profession}</p>
            </div>
            <div className="flex flex-row justify-between flex-wrap gap-8">
                <div className="flex flex-col gap-4 w-[48%] min-w-64">
                    <h4 className="text-base font-semibold">Profesion</h4>
                    <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                        <p>{searchResults?.category}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-[48%] min-w-64">
                    <h4 className="text-base font-semibold">Nombre</h4>
                    <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                        <p>{searchResults?.name}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-[48%] min-w-64">
                    <h4 className="text-base font-semibold">Apellido</h4>
                    <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                        <p>{searchResults?.lastname}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-[48%] min-w-64">
                    <h4 className="text-base font-semibold">Email</h4>
                    <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                        <p>{searchResults?.email}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-[48%] min-w-64">
                    <h4 className="text-base font-semibold">Teléfono</h4>
                    <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                        <p>{searchResults?.phone}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageProfessional;