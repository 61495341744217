import { coreAdapter } from '../../adapters/core-adapter';
import { ButtonRedBig } from '../../components/Buttons';
import { InputTitleFull } from '../../components/Inputs';
import { useFieldInputs } from '../../hooks/useField';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { SignIn } from '../../services/Auth';
import { useAxiosWithLoading } from '../../services/config/config';

const Login = () => {
  const { logo } = coreAdapter();
  const user = useFieldInputs({ title: 'Usuario', defaultValue: '' });
  const password = useFieldInputs({ title: 'Contraseña', defaultValue: '' });
  const auth = useAuth();
  const navigate = useNavigate();
  const axiosInstance = useAxiosWithLoading();

  const handleSubmit = (event) => {
    event.preventDefault();
    SignIn({
      email: user.value,
      password: password.value,
      auth,
      navigate,
      axiosInstance
    })
  };

  return (
    <div className="flex flex-col gap-8 items-center justify-center w-full h-full">
      <img src={logo} className="w-[20vw]" alt="logo" />
      <h2 className="text-2xl font-medium">Bienvenido, Inicia sesión</h2>
      <form className="w-[30vw] flex flex-col gap-4" onSubmit={handleSubmit}>
        <InputTitleFull {...user} type={'email'} />
        <InputTitleFull {...password} type={'password'} />
        <ButtonRedBig text={'Iniciar sesión'} type="submit" />
      </form>
    </div>
  );
};

export default Login;
