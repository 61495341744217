import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
};

const UserSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: {
    setUser(state, action) {
      return {
        ...state,
        user: action.payload
      }
    },
  },
});

export const { setUser } = UserSlice.actions;

export default UserSlice.reducer;