import React, { useRef, useState } from 'react';
import { ButtonRedBig } from "../../../components/Buttons";
import { InputTitle } from "../../../components/Inputs";
import { useFieldInputs } from "../../../hooks/useField";
import SelectList from '../../../components/SelectList';
import { createRealStateProperty } from '../../../services/RealState';
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { useAxiosWithLoading } from '../../../services/config/config';

const AddRealEstate = () => {
    const property_name = useFieldInputs({ title: "Nombre", defaultValue: "" });
    const province = useFieldInputs({ title: "Provincia", defaultValue: "" });
    const locality = useFieldInputs({ title: "Localidad", defaultValue: "" });
    const floor = useFieldInputs({ title: "Piso", defaultValue: 0 });
    const address = useFieldInputs({ title: "Dirección", defaultValue: "" });
    const price = useFieldInputs({ title: "Precio (Mensual)", defaultValue: 0 });
    const phone = useFieldInputs({ title: "Teléfono de contacto", defaultValue: "" });
    const rooms_number = useFieldInputs({ title: "Numero de habitaciones", defaultValue: 0 });
    const floors_number = useFieldInputs({ title: "Numero de plantas (Pisos)", defaultValue: 0 });
    const bathrooms_number = useFieldInputs({ title: "Baños", defaultValue: 0 });
    const description = useFieldInputs({ title: "Descripción", defaultValue: "" });
    const square_meter = useFieldInputs({ title: "Metros cuadrados", defaultValue: 0 });
    const email = useFieldInputs({ title: "Email de contacto", defaultValue: "" });
    const [swimmingpool, setSwimmingpool] = useState(false);
    const [parking, setParking] = useState(false);
    const [property_type, setProperty_type] = useState("");
    const [rental, setRental] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const { token } = useAuth();
    const navigate = useNavigate();

    const optionListType = [
        { text: "CASA", value: "CASA" },
        { text: "DEPARTAMENTO", value: "DEPARTAMENTO" },
        { text: "CABAÑA", value: "CABANA" },
    ];

    const handleChangePool = (e) => setSwimmingpool(e.target.checked);
    const handleChangeParking = (e) => setParking(e.target.checked);
    const handleOptionChange = (event) => setRental(event.target.value);

    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages(prevImages => [...prevImages, ...files]);
        const newImageUrls = files.map(file => URL.createObjectURL(file));
        setImageUrls(prevUrls => [...prevUrls, ...newImageUrls]);
    };

    const handleRemoveImage = (index) => {
        setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
        setImageUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
    };
    const axiosInstance = useAxiosWithLoading();

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append("property_name", property_name.value);
        formData.append("province", province.value);
        formData.append("locality", locality.value);
        formData.append("address", address.value);
        formData.append("floor", floor.value);
        formData.append("rental", rental);
        formData.append("price", price.value);
        formData.append("phone", phone.value);
        formData.append("email", email.value);
        formData.append("property_type", property_type);
        formData.append("rooms_number", rooms_number.value);
        formData.append("bathrooms_number", bathrooms_number.value);
        formData.append("description", description.value);
        formData.append("square_meter", square_meter.value);
        formData.append("floors_number", floors_number.value);
        formData.append("parking", parking);
        formData.append("swimmingpool", swimmingpool);
        
        selectedImages.forEach((image, index) => {
            formData.append(`image`, image);
        });

        await createRealStateProperty({ token, data: formData, navigate, axiosInstance });
    };

    return (
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">Nueva Propiedad</p>
            <div className="flex flex-row flex-wrap gap-8">
                {imageUrls.length >= 0 && (
                    imageUrls.map((url, index) => (
                        <div key={index} className="relative w-60 h-36 bg-sidebar">
                            <img src={url} alt={`imagen-${index}`} className="w-full h-full object-cover" />
                            <button onClick={() => handleRemoveImage(index)} className="absolute top-2 right-2 bg-highligth text-white rounded-full h-6 w-6">×</button>
                        </div>
                    ))
                )}
                {imageUrls.length < 15 && (
                    <button onClick={handleImageClick} className="w-60 h-36 bg-white border border-black hover:bg-hover duration-300 transition-all">
                        <p className="text-7xl font-semibold">+</p>
                        <p className="text-base font-semibold">Agregar fotos</p>
                    </button>
                )}
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept="image/*" multiple onChange={handleFileChange} />
            </div>
            <div className="flex flex-row justify-around flex-wrap gap-x-[2%] gap-y-8">
                <InputTitle {...property_name} />
                <InputTitle {...price} type={"number"} />
                <SelectList options={optionListType} title={"Tipo de vivienda"} placeholder={"Selecciona un Tipo de vivienda"} selectedOption={property_type} setSelectedOption={setProperty_type} />
                <div className="flex flex-row items-center w-[48%] gap-[10%]">
                    <label className='flex flex-row gap-2 items-center text-base font-semibold'>
                        Compra
                        <input type="radio" value="COMPRA" checked={rental === 'COMPRA'} onChange={handleOptionChange} />
                    </label>
                    <label className='flex flex-row gap-2 items-center text-base font-semibold'>
                        Alquiler
                        <input type="radio" value="ALQUILER" checked={rental === 'ALQUILER'} onChange={handleOptionChange} />
                    </label>
                </div>
                <InputTitle {...description} />
                <InputTitle {...address} />
                <InputTitle {...province} />
                <InputTitle {...locality} />
                <InputTitle {...square_meter} type={"number"} />
                <InputTitle {...floor} type={"number"} />
                <InputTitle {...floors_number} type={"number"} />
                <InputTitle {...rooms_number} type={"number"} />
                <InputTitle {...bathrooms_number} type={"number"} />
                <div className='flex flex-row w-[48%] items-center gap-[10%]'>
                    <div className="flex flex-row gap-4 items-center w-[10%]">
                        <label className="text-base font-semibold">Piscina</label>
                        <input type="checkbox" checked={swimmingpool} onChange={handleChangePool} />
                    </div>
                    <div className="flex flex-row gap-4 items-center w-[10%]">
                        <label className="text-base font-semibold">Estacionamiento</label>
                        <input type="checkbox" checked={parking} onChange={handleChangeParking} />
                    </div>
                </div>
                <InputTitle {...phone} />
                <InputTitle {...email} />
            </div>
            <div className="flex flex-row items-center justify-center gap-8 w-full">
                <ButtonRedBig text={"Guardar"} onClick={handleSubmit} />
            </div>
        </div>
    );
};

export default AddRealEstate;
