import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { ButtonRedBig } from '../../../../components/Buttons';
import { createMultipleOwners } from '../../../../services/User';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../context/AuthContext';
import { useAxiosWithLoading } from '../../../../services/config/config';

const NewOwnerExcel = () => {
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate()
  const {token} = useAuth()
  const {community_id} = useParams()

  const keyMapping = {
    'Grupo': 'group',
    'Codigo': 'code',
    'Propietario': 'name',
    'Cuenta Contable': 'accountingAccount',
    'Nombre Cta.Contable': 'nameAccountingAccount',
    '¿Domiciliado?': 'domiciled',
    'Cta.Bancaria': 'bankAccount',
    'Dirección': 'address',
    'Nombre Finca': 'fincaNum',
    'Email': 'emails',
    'Buzón': 'fax',
    'Teléfonos': 'phones'
  };

  const allowedKeys = [
    'group', 'code', 'lastName', 'name', 'accountingAccount', 'nameAccountingAccount', 'domiciled', 'bankAccount', 'address', 'fincaNum', 'emails', 'fax', 'phones'
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setFileName('');
      return;
    }
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setData(jsonData);
    };
    reader.readAsBinaryString(file);
  };

  const processDataList = (dataList) => {
    return dataList.map(obj => {
      const newObj = {};
      for (const key in obj) {
        const mappedKey = keyMapping[key];
        if (mappedKey && allowedKeys.includes(mappedKey)) {
          if (typeof obj[key] === 'string') {
            if (key === 'Teléfonos' || key === 'Email') {
              newObj[mappedKey] = obj[key].split('\n').filter(item => item.trim() !== '');
            } else if (key === 'Dirección') {
              newObj[mappedKey] = obj[key].replace(/\r\n/g, ', ').trim();
            } else if (obj[key] === 'Si') {
              newObj[mappedKey] = true;
            } else if (obj[key] === 'No') {
              newObj[mappedKey] = false;
            } else if (key === 'Propietario') {
              const [lastName, name] = obj[key].split(', ');
              newObj['lastName'] = lastName;
              newObj['name'] = name;
            } else if (key === 'Nombre Finca') {
              newObj[mappedKey] = obj[key].replace(/^Finca\s+/i, '');
            } else {
              newObj[mappedKey] = obj[key];
            }
          } else {
            newObj[mappedKey] = obj[key];
          }
        }
      }
      return newObj;
    });
  };

  const processedData = processDataList(data);
  const axiosInstance = useAxiosWithLoading();

  const handleSubmit = () => {
    createMultipleOwners({
      data: processedData,
      navigate,
      token,
      community_id,
      axiosInstance
    })
  }

  return (
    <div className="container mx-auto p-4">
      <header className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-medium">Registrar propietarios por Excel</h1>
      </header>
      <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-row items-center border-2 border-black rounded-full'>
          <input type="file" className="hidden" id="fileInput" onChange={handleFileUpload}/>
          <label htmlFor="fileInput" className="bg-primary text-white py-2 px-11 rounded-full hover:bg-opacity-80 transition-all duration-300 cursor-pointer">Subir archivo</label>
          <div className='py-1.5 px-8 text-center'>{fileName ? fileName : "Seleccionar un archivo"}</div>
        </div>
        {processedData.length > 0 && (
          <ButtonRedBig
          onClick={handleSubmit}
          text={"Registrar propietarios"}
          />
        )}
      </div>
      <div className="mt-4">
        {
          processedData.map((owner, index) => (
            <div key={index} className="p-4 border-b-2 border-black">
              <div className="flex">
                <div className="w-1/2">
                  <strong>Grupo:</strong> {owner.group || "-"}
                </div>
                <div className="w-1/2">
                  <strong>Codigo:</strong> {owner.code}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <strong>Apellido:</strong> {owner.lastName}
                </div>
                <div className="w-1/2">
                  <strong>Nombre:</strong> {owner.name}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <strong>Cuenta Contable:</strong> {owner.accountingAccount}
                </div>
                <div className="w-1/2">
                  <strong>Nombre Cuenta de Contable:</strong> {owner.nameAccountingAccount}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <strong>Domiciliado:</strong> {owner.domiciled ? 'Sí' : 'No'}
                </div>
                <div className="w-1/2">
                  <strong>Cuenta Bancaria:</strong> {owner.bankAccount || "-"}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <strong>Dirección:</strong> {owner.address}
                </div>
                <div className="w-1/2">
                  <strong>Número de Finca:</strong> {owner.fincaNum}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <strong>Email/s:</strong>
                  <ul>
                    {Array.isArray(owner.emails) ? owner.emails.map((email, i) => <li key={i}>{`- ${email}`}</li>) : <li>{`- ${owner.email}`}</li>}
                  </ul>
                </div>
                <div className="w-1/2">
                  <strong>Teléfono/s:</strong>
                  <ul>
                    {Array.isArray(owner.phones) ? owner.phones.map((phone, i) => <li key={i}>{`- ${phone}`}</li>) : <li>{`- ${owner.phone}`}</li>}
                  </ul>
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <strong>Buzón:</strong> {owner.fax || "-"}
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default NewOwnerExcel;
