import { useEffect, useState } from "react";
import { getCommunityById } from "../../../../services/Communities";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useAxiosWithLoading } from "../../../../services/config/config";

const Information = () => {

    const {community_id} = useParams()
    const {token} = useAuth()
    const [community, setCommunity] = useState({})
    const axiosInstance = useAxiosWithLoading();
    const fetchCommunity = async () => {
        const fetchedEmployees = await getCommunityById({ token, id: community_id, axiosInstance });
        setCommunity(fetchedEmployees);
    };
    useEffect(() => {
        fetchCommunity()
        // eslint-disable-next-line
    },[])
    
    return(
        <div className="flex flex-row justify-between flex-wrap gap-8">
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">Nombre de la comunidad</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.community_name}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">Calle</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.street}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">País</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.country}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">Provincia</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.province}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">Localidad</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.location}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">Código Postal</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.postal_code}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">CCC</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.community_ccc}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">CIF</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.community_cif}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
                <h4 className="text-base font-semibold">Encargado</h4>
                <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
                    <p>{community?.community_manager_name}</p>
                </div>
            </div>
        </div>
    )
}

export default Information