import { useParams } from "react-router-dom";
import { ListsExtractsOwner } from "../../../../components/Lists";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { getExtracts } from "../../../../services/Extracts";
import { useAxiosWithLoading } from "../../../../services/config/config";

const OwnerExtracts = () => {
    
  const categies = [ "Finca", "Periodo de Inicio", "Periodo de Fin", "Gestionar"]
  const keys = ['finca_number', 'starting_period', 'end_period'];

  const {community_id, owner_id} = useParams()
  const {token} = useAuth()
  const [extracts, setExtracts] = useState([]);
  const axiosInstance = useAxiosWithLoading();

  const fetchExtracts = async () => {
      const fetchedExtracts = await getExtracts({ token, community_id, user_id: owner_id, axiosInstance });
      Array.isArray(fetchedExtracts) ? setExtracts(fetchedExtracts) : setExtracts([])
  };
  useEffect(() => {
      fetchExtracts()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-1 flex-col gap-10">
      <ListsExtractsOwner categies={categies} keys={keys} items={extracts} buttonText={"Ver"} />
    </div>
  )
}

export default OwnerExtracts;