export const listVotesCommunities = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/vote/list-communities/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const createVote = async ({ token, data, navigate, axiosInstance }) => {
    try {
        await axiosInstance.post(`/vote/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        navigate("..");
    } catch (error) {
        console.error(error);
    }
};

export const detailVoteCommunity = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/vote/detail-vote-community/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.vote;
    } catch (error) {
        console.error(error);
    }
};

export const QuestionAnswerData = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/vote/question-answer/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.answers;
    } catch (error) {
        console.error(error);
    }
};

export const detailQuestion = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/vote/detail-question/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};