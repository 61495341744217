import { useEffect, useState } from "react";
import Lists from "../../../../../components/Lists";
import { useParams } from "react-router-dom";
import { detailVoteCommunity } from "../../../../../services/Votes";
import { useAuth } from "../../../../../context/AuthContext";
import { useAxiosWithLoading } from "../../../../../services/config/config";

const VotesQuestions = () => {
    
  const categies = [ "Pregunta", "Fecha", "Cantidad de opciones", "Gestionar"]
  const keys = ['question', 'createDAt', 'answer_quantity'];
  const [questions, setQuestions] = useState([])
  const {vote_id} = useParams()
  const {token} = useAuth()
  const axiosInstance = useAxiosWithLoading();

  const fetchQuestions = async () => {
    const fetchedQuestions = await detailVoteCommunity({ token, id: vote_id, axiosInstance });
    Array.isArray(fetchedQuestions) ? setQuestions(fetchedQuestions) : setQuestions([])
  };

  useEffect(() => {
      fetchQuestions()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-1 flex-col gap-10">
      <p className="text-2xl font-medium"></p>
      <Lists categies={categies} keys={keys} items={questions} buttonText={"Ver"} />
    </div>
  )
}

export default VotesQuestions;