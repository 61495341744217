import React, { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../context/AuthContext';
import { downloadExtract } from '../../../../../services/Extracts';
import { useAxiosWithLoading } from '../../../../../services/config/config';

const PdfViewer = ({ pdfData }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const loadPdf = async () => {
      const pdfDoc = await PDFDocument.load(pdfData);
      const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });
      setPdfUrl(pdfBytes);
    };
    // eslint-disable-next-lin
    if (pdfData) {
      loadPdf();
    }
  }, [pdfData]);

  return (
    <div>
      {
        pdfUrl ? (<iframe src={pdfUrl} title='pdf' width="100%" height="800px" frameBorder="0" ></iframe>
          
        ) : (
          <p>No se pudo cargar el PDF.</p>
        )
      }
    </div>
  );
};

const ViewExtract = () => {
  const [pdfData, setPdfData] = useState(null);
  const {token} = useAuth()
  const {data, community_id} = useParams()
  const [owner_id, starting, end] = data.split(" ");
  const axiosInstance = useAxiosWithLoading();

  const fetchExtract = async () => {
      const fetchedExtract = await downloadExtract({ 
        token,
        data: {
          starting_period: starting,
          end_period: end,
          community_id: community_id,
          owner_id: owner_id
        },
        axiosInstance
      });
      setPdfData(fetchedExtract)
  };

  useEffect(() => {
    fetchExtract()
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {
        pdfData ? <PdfViewer pdfData={pdfData} /> : <p>No se pudo cargar el PDF.</p>
      }
    </div>
  );
};

export default ViewExtract;
