import { useNavigate } from "react-router-dom";
import { ButtonRedBig } from "../../components/Buttons";
import Professions from "./subpages/Professions";

const Professionals = () => {
    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };
    return(
        <div className="flex flex-col gap-10">
            <div className="flex flex-row justify-between items-center">
                <p className="text-2xl font-medium">Listado de profesionales</p>
                <ButtonRedBig text={"Nuevo Profesional"} onClick={() => handleClick("./new-professional")} />
            </div>
            <Professions />
        </div>
    )
}

export default Professionals;