import { useEffect, useState } from "react";
import Lists from "../../../../components/Lists";
import { getTickets } from "../../../../services/Tickets";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useAxiosWithLoading } from "../../../../services/config/config";

const OwnerTickets = () => {
    
  const categies = [ "Asunto", "Fecha", "Estado", "Gestionar"]
  const keys = ['description', 'createdAt', 'status'];
  const {owner_id} = useParams()
  const {token} = useAuth()
  const axiosInstance = useAxiosWithLoading();

  const [tickets, setTickets] = useState([])
  const fetchTickets = async () => {
    const fetchedTickets = await getTickets({ token, id: owner_id, axiosInstance });
    Array.isArray(fetchedTickets) ? setTickets(fetchedTickets) : setTickets([])
  };
  useEffect(() => {
      fetchTickets()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-1 flex-col gap-10">
      <Lists categies={categies} keys={keys} items={tickets} buttonText={"Acceder"} />
    </div>
  )
}

export default OwnerTickets;