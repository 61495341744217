import { useEffect, useState } from "react"
import { ButtonRedBig } from "../../components/Buttons"
import { InputTitle } from "../../components/Inputs"
import { useFieldInputs } from "../../hooks/useField"
import { useSelector } from "react-redux"
import { updateUser } from "../../services/User"
import { useAuth } from "../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { useAxiosWithLoading } from "../../services/config/config"

const Settings = () => {

  const {user} = useSelector((state) => state.user)
  const name = useFieldInputs({title: "Nombre", defaultValue: user?.name})
  const lastname = useFieldInputs({title: "Apellido", defaultValue: user?.lastname})
  const email = useFieldInputs({title: "Correo electrónico", defaultValue: user?.email})
  const phone = useFieldInputs({title: "Teléfono", defaultValue: user?.phone})
  const password = useFieldInputs({title: "Contraseña Actual o Nueva", defaultValue: ""})
  const [disabled, setDisabled] = useState(true)
  const {token} = useAuth()
  const navigate = useNavigate()
  const axiosInstance = useAxiosWithLoading();
  const [error, setError] = useState("")

  const handleDisabled = () => {
    if (password.value) {
      setDisabled(false)
      return
    } else if(name.value !== user?.name && password.value){
      setDisabled(false)
      return
    } else if (lastname.value !== user?.lastname && password.value) {
      setDisabled(false)
      return
    } else if (email.value !== user?.email && password.value) {
      setDisabled(false)
      return
    } else if (phone.value !== user?.phone && password.value) {
      setDisabled(false)
      return
    } else {
      setDisabled(true)
      return
    }
  }

  useEffect(() => {
    handleDisabled()
    // eslint-disable-next-line
  },[name.value, lastname.value, email.value, phone.value, password.value])

  const passwordRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[0-9])/;
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/;
  
  const handleSubmit = () => {
    if (!passwordRegex.test(password.value)) {
      setError('La contraseña debe poseer mayusculas, minusculas, numeros y mas de 8 caracteres');
    } else if (!emailRegex.test(email.value)) {
      setError('El correo electrónico no cumple con los requisitos.');
    } else {
      updateUser({
        data: {
          "name": name.value,
          "lastname": lastname.value,
          "email": email.value,
          "phone": phone.value,
          "password": password.value
        },
        token,
        navigate,
        axiosInstance,
        user_id: user.id,
        setError
      })
    }
  
  }

  return(
    <div className="flex flex-1 flex-col gap-10">
      <p className="text-2xl font-medium">Configuraciones</p>
      <div className="flex flex-row justify-between flex-wrap gap-8">
        <InputTitle {...name} />
        <InputTitle {...lastname} />
        <InputTitle {...email} />
        <InputTitle {...phone} />
        <InputTitle {...password} />
      </div>
      {
        error && <p className="text-center text-red-500 text-sm">{error}</p>
      }
      <ButtonRedBig text={"Guardar"} disabled={disabled} onClick={handleSubmit} />
    </div>
  )
}

export default Settings;