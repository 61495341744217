import { Link, useLocation } from "react-router-dom";

const OptionsTabs = ({tabs}) => {
    
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const lastSegment = pathParts[pathParts.length - 1];

    return(
        <div className="flex flex-row flex-wrap gap-x-8 gap-y-4 pb-4 border-b-2 border-black">
            {tabs.map((item, i) => (
                <Link to={item.path} key={i} className={`${lastSegment === item.route || lastSegment + "/" === item.route ? "text-white hover:bg-opacity-80 transition-all duration-300" : "bg-opacity-0 text-black hover:bg-opacity-80 hover:text-white" } bg-primary w-44 px-4 py-1 rounded-lg text-center transition-all duration-300`}>{item.text}</Link>
            ))}
        </div>
    )
}

export const FilterTabs = ({tabs, selected, setSelected}) => {

    return(
        <div className="flex flex-row flex-wrap gap-x-8 gap-y-4 pb-4 border-b-2 border-black">
            {tabs.map((item, i) => (
                <button key={i} onClick={() => setSelected(item.value)} className={`${selected === item.value ? "text-white hover:bg-opacity-80 transition-all duration-300" : "bg-opacity-0 text-black hover:bg-opacity-80 hover:text-white" } bg-primary w-44 px-4 py-1 rounded-lg text-center transition-all duration-300`}>{item.text}</button>
            ))}
        </div>
    )
}

export default OptionsTabs;