import { useNavigate, useParams } from "react-router-dom"
import { ButtonRedBig } from "../../../../../components/Buttons"
import { InputTitle } from "../../../../../components/Inputs"
import { useAuth } from "../../../../../context/AuthContext"
import { useFieldInputs } from "../../../../../hooks/useField"
import { useState } from "react"
import { createCommonZones } from "../../../../../services/CommonZones"
import { useAxiosWithLoading } from "../../../../../services/config/config"


const NewCommonArea = () => {

  const name = useFieldInputs({title: "Nombre de la zona", defaultValue: ""})
  const places = useFieldInputs({title: "Numero de plazas", defaultValue: ""})
  const [date, setDate] = useState('');
  const {token} = useAuth()
  const {community_id} = useParams()
  const navigate = useNavigate()
  const axiosInstance = useAxiosWithLoading();

  const handleChangeDate = (e) => setDate(e.target.value);


  const handleSubmit = () => {
    createCommonZones({
      id: community_id,
      data: {
        "zone_name": name.value,
        "number_places": places.value,
        "date": date
      },
      token,
      navigate,
      axiosInstance
    })
  }

  return(
    <div className="flex flex-1 flex-col gap-10">
      <p className="text-2xl font-medium">Nueva zona común</p>
      <div className="flex flex-row justify-between flex-wrap gap-8">
        <InputTitle {...name} />
        <InputTitle {...places} />
        <div className="flex flex-col gap-4 w-[48%]">
          <label className="text-base font-semibold">Fecha</label>
          <input type="date" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={date} onChange={handleChangeDate} />
        </div>
      </div>
      <ButtonRedBig text={"Guardar"} onClick={handleSubmit} />
    </div>
  )
}

export default NewCommonArea;