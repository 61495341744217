import { useEffect, useState } from "react"
import { ButtonRedBig } from "../../../../components/Buttons"
import { InputTitle } from "../../../../components/Inputs"
import SelectList from "../../../../components/SelectList"
import { useFieldInputs } from "../../../../hooks/useField"
import { useAuth } from "../../../../context/AuthContext"
import { getEmployees } from "../../../../services/Employee"
import { createCommunity } from "../../../../services/Communities"
import { useNavigate } from "react-router-dom"
import { useAxiosWithLoading } from "../../../../services/config/config"

const AddCommunity = () => {
   
    const {token} = useAuth()
    const [employees, setEmployees] = useState([])
    const name = useFieldInputs({title: "Nombre de la comunidad", defaultValue: ""})
    const street = useFieldInputs({title: "Calle", defaultValue: ""})
    const country = useFieldInputs({title: "País", defaultValue: ""})
    const province = useFieldInputs({title: "Provincia", defaultValue: ""})
    const location = useFieldInputs({title: "Localidad", defaultValue: ""})
    const postalCode = useFieldInputs({title: "Código Postal", defaultValue: ""})
    const ccc = useFieldInputs({title: "CCC", defaultValue: ""})
    const cif = useFieldInputs({title: "CIF", defaultValue: ""})
    const [manager, setManager] = useState('');
    const navigate = useNavigate()
    const axiosInstance = useAxiosWithLoading();

    const fetchEmployees = async () => {
        const fetchedEmployees = await getEmployees({ token, axiosInstance });
        setEmployees(fetchedEmployees);
    };

    useEffect(() => {
        fetchEmployees();
        // eslint-disable-next-line
    },[])

    const handleSubmit = () => {
        createCommunity({
            token,
            data: {
                name: name.value,
                manager_id: Number(manager),
                country: country.value,
                province: province.value,
                location: location.value,
                street: street.value,
                postalCode: postalCode.value,
                cif: cif.value,
                ccc: ccc.value
            },
            navigate,
            axiosInstance
        })
    }
    

    const optionList = employees.map((item) => {
        return {
            text: `${item.name} ${item.lastName}`,
            value: item.id
        }
    })
    const disabled = name.value && street.value && province.value && location.value && postalCode.value && cif.value && ccc.value && manager

    return(
        <div className="flex flex-1 flex-col gap-10">
            <p className="text-2xl font-medium">Registrar Nueva Comunidad</p>
            <div className="flex flex-row justify-between flex-wrap gap-8">
                <InputTitle {...name} />
                <InputTitle {...street} />
                <InputTitle {...country} />
                <InputTitle {...province} />
                <InputTitle {...location} />
                <InputTitle {...postalCode} />
                <InputTitle {...ccc} />
                <InputTitle {...cif} />
                <SelectList options={optionList ? optionList : {}} title={"Id del Encargado"} placeholder={"Selecciona o Escribe un Encargado"} selectedOption={manager} setSelectedOption={setManager} />
            </div>
            <ButtonRedBig text={"Guardar"} disabled={!disabled} onClick={handleSubmit} />
        </div>
    )
}

export default AddCommunity;