import { useEffect, useState } from "react";
import { ListsReceipts } from "../../../../components/Lists";
import { useAuth } from "../../../../context/AuthContext";
import { useParams } from "react-router-dom";
import { getReceiptsByUserId } from "../../../../services/Receipts";
import { useAxiosWithLoading } from "../../../../services/config/config";

const OwnerReceipts = () => {
    
  const categies = [ "Propietario", "Periodo de Inicio", "Periodo de Fin", "Gestionar"]
  const keys = ['owner_id', 'starting_period', 'end_period'];

  const {community_id, owner_id} = useParams()
  const {token} = useAuth()
  const [receipts, setReceipts] = useState([]);
  const axiosInstance = useAxiosWithLoading();

  const fetchReceipts = async () => {
    const fetchedReceipts = await getReceiptsByUserId({ token, community: community_id, user_id: owner_id, axiosInstance});
    Array.isArray(fetchedReceipts) ? setReceipts(fetchedReceipts) : setReceipts([])
  };

  useEffect(() => {
      fetchReceipts()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-1 flex-col gap-10">
      <ListsReceipts categies={categies} keys={keys} items={receipts} buttonText={"Ver"} />
    </div>
  )
}

export default OwnerReceipts;