import React, { createContext, useContext, useState, useEffect } from 'react';
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  useEffect(() => {
    if (!token) {
      setToken(localStorage.getItem('token'));
    }
    // eslint-disable-next-line
  }, []);

  const login = (userData) => {
    localStorage.setItem('token', userData);
    setToken(userData);
  };
  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
  };
  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);