import { useState } from 'react';
import { createReceipt } from '../../../../../services/Receipts';
import { useAuth } from '../../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useAxiosWithLoading } from '../../../../../services/config/config';

const NewReceiptManual = () => {
  const [finca, setFinca] = useState('');
  const [person, setPerson] = useState('');
  const [header, setHeader] = useState('');
  const [period, setPeriod] = useState('');
  const [domiciled, setDomiciled] = useState(false);
  const [description, setDescription] = useState('');
  const [totalReceipt, setTotalReceipt] = useState('');
  const [endPeriod, setEndPeriod] = useState('');
  const {token} = useAuth()
  const navigate = useNavigate()

  const handleChangeFinca = (e) => setFinca(e.target.value);
  const handleChangePerson = (e) => setPerson(e.target.value);
  const handleChangeHeader = (e) => setHeader(e.target.value);
  const handleChangePeriod = (e) => setPeriod(e.target.value);
  const handleChangeDomiciled = (e) => setDomiciled(e.target.checked);
  const handleChangeDescription = (e) => setDescription(e.target.value);
  const handleChangeTotalReceipt = (e) => setTotalReceipt(e.target.value);
  const handleChangeEndPeriod = (e) => setEndPeriod(e.target.value);
  const axiosInstance = useAxiosWithLoading();

  const handleSubmit = (e) => {
    e.preventDefault();
    createReceipt({
      data: {
        end_period: endPeriod,
        receipt: [
          {
            "finca_number": Number(finca),
            "header_number": Number(header),
            "period_number": Number(period),
            "domiciled": domiciled,
            "concept": description,
            "amount": Number(totalReceipt)
          }
        ]
      },
      token,
      navigate,
      axiosInstance
    })
  };

  const disabled = !endPeriod || !finca || !header || !period || !description || !totalReceipt

  return (
    <div className="container mx-auto p-4 overflow-auto">

      <main>
        <form className="space-y-10" onSubmit={handleSubmit}>
          <div className='flex flex-row items-center justify-between'>
            <header className="flex justify-between items-center mb-4">
              <h1 className="text-2xl font-medium">Nuevo recibo</h1>
            </header>
            <button type="submit" disabled={disabled} className={`${disabled ? "disabled:bg-gray-500" : "bg-highligth"} text-white px-4 py-2 rounded-full`}>Enviar recibo</button>
          </div>

          <div className='flex flex-col gap-4'>
            <div className="flex flex-col gap-4 w-[48%]">
              <label className="text-base font-semibold">Dias a terminar el periodo</label>
              <input type="number" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={endPeriod} onChange={handleChangeEndPeriod} />
            </div>
            <div className="flex flex-row gap-x-[4%]">
              <div className="flex flex-col gap-4 w-[48%]">
                <label className="text-base font-semibold">Finca</label>
                <input type="number" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={finca} onChange={handleChangeFinca} />
              </div>
              <div className="flex flex-col gap-4 w-[48%]">
                <label className="text-base font-semibold">Persona</label>
                <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={person} onChange={handleChangePerson} />
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <label className="text-base font-semibold">Domiciliado</label>
              <input type="checkbox" checked={domiciled} onChange={handleChangeDomiciled} />
            </div>
          </div>

          <div id="rowsContainer" className='flex flex-col gap-4'>
            <div className="flex flex-row gap-4 items-end">
              <div className="flex flex-col gap-4 w-full">
                <label className="text-base font-semibold">Cabecera</label>
                <input type="number" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={header} onChange={handleChangeHeader} />
              </div>
              <div className="flex flex-col gap-4 w-full">
                <label className="text-base font-semibold">Periodo</label>
                <input type="number" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={period} onChange={handleChangePeriod} />
              </div>
              <div className="flex flex-col gap-4 w-full">
                <label className="text-base font-semibold">Descripción</label>
                <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={description} onChange={handleChangeDescription} />
              </div>
              <div className="flex flex-col gap-4 w-full">
                <label className="text-base font-semibold">Total</label>
                <input type="number" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={totalReceipt} onChange={handleChangeTotalReceipt} />
              </div>
            </div>
          </div>

          <div className='flex flex-row items-center justify-center gap-4'>
          </div>
        </form>
      </main>
    </div>
  );
}

export default NewReceiptManual;
