export const SignIn = async ({ email, password, auth, navigate, axiosInstance }) => {
  try {
    const response = await axiosInstance.post('/auth/signin', {
      email,
      password
    });
    auth.login(response.data.token);
    navigate('/');
  } catch (error) {
    console.error(error);
  }
};
