import { useState } from "react";

export const useFieldInputs = ({ title, placeholder, defaultValue }) => {
    const [value, setValue] = useState(defaultValue);
    const onChange = (e) => setValue(e.target.value)
    return{
        title,
        value,
        onChange,
        placeholder,
    }
}