export const getProfessionalByCategory = async ({ token, profession, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/professional/list/${profession}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const createProfessional = async ({ token, data, navigate, axiosInstance }) => {
    try {
        await axiosInstance.post('/professional/create-professional', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        navigate("..");
    } catch (error) {
        console.error(error);
    }
};

export const getProfessionalById = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/professional/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.professional;
    } catch (error) {
        console.error(error);
    }
};