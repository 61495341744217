import { ButtonRedBig } from "../../components/Buttons";
import SearchBar from "../../components/SearchBar";
import Lists from "../../components/Lists";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import OptionsTabs from "../../components/OptionsTabs";
import { useEffect, useState } from "react";
import { getCommunityById, quantityUsersInCommunity } from "../../services/Communities";
import { useAuth } from "../../context/AuthContext";
import { useAxiosWithLoading } from "../../services/config/config";

// Lista de las Comunidades
export const CommunitiesList = () => {
    
    const {token} = useAuth()
    const [communities, setCommunities] = useState([])
    const categies = [ "Comunidad", "Encargado", "Propietarios", "Ultima actualización", "Gestionar"]
    const keys = ['name', 'manager', 'totalOwners', 'updatedAt'];
    const [searchResults, setSearchResults] = useState([]);
    const axiosInstance = useAxiosWithLoading();

    const fetchCommunities = async () => {
        const fetchedCommunities = await quantityUsersInCommunity({ token, axiosInstance });
        Array.isArray(fetchedCommunities) ? setCommunities(fetchedCommunities) : setCommunities([])
        Array.isArray(fetchedCommunities) ? setSearchResults(fetchedCommunities) : setSearchResults([])
    };
    useEffect(() => {
        fetchCommunities()
        // eslint-disable-next-line
    },[])

    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };

    return(
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">Comunidades</p>
            <div className="flex flex-row items-center justify-between w-full">
                <SearchBar
                    placeholder="Buscar Comunidad"
                    data={communities}
                    searchParams={['name']}
                    onResults={setSearchResults}
                />
                <ButtonRedBig text={"Nueva Comunidad"}  onClick={() => handleClick("./new-community")} />
            </div>
            <Lists categies={categies} keys={keys} items={searchResults} buttonText={"Acceder"} />
        </div>
    )
}

// Informacion de la comunidad al acceder
export const CommunityInfo = () => {
    
    const { community_id } = useParams();
    const {token} = useAuth()
    const [community, setCommunity] = useState({})
    const axiosInstance = useAxiosWithLoading();
    const fetchCommunity = async () => {
        const fetchedCommunity = await getCommunityById({ token, id: community_id, axiosInstance });
        setCommunity(fetchedCommunity)
    };
    useEffect(() => {
        fetchCommunity()
        // eslint-disable-next-line
    },[])

    const tabs = [
        {text: "Información", route: community_id, path: ""},
        {text: "Propietarios", route: `owners`, path: "owners"},
        {text: "Actas e Informes", route: `records-reports`, path: "records-reports"},
        {text: "Votaciones", route: `votes`, path: "votes"},
        {text: "Zonas comunes", route: `common-areas`, path: "common-areas"},
        {text: "Recibos", route: `receipts`, path: "receipts"},
        {text: "Extractos", route: `extracts`, path: "extracts"},
    ] 
    const location = useLocation();
    const hideForSubOwners = 
    /\/owners\/(?!$)/.test(location.pathname) || 
    /\/records-reports\/(?!$)/.test(location.pathname) || 
    /\/votes\/(?!$)/.test(location.pathname) || 
    /\/extracts\/(?!$)/.test(location.pathname) ||
    /\/common-areas\/(?!$)/.test(location.pathname) ||
    /\/receipts\/(?!$)/.test(location.pathname)



    return (
        <div className="flex flex-col gap-10">
            {!hideForSubOwners && (
                <div className="flex flex-col gap-10">
                    <p className="text-2xl font-medium">{community?.community_name}</p>
                    <OptionsTabs tabs={tabs} id={community_id} />
                </div>
            )}
            <Outlet />
        </div>
    )
}