import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { Socket, useAxiosWithLoading } from '../../services/config/config';
import { useSelector } from "react-redux"
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonRedBig } from '../../components/Buttons';
import { closeTicket, getChatMessage, TicketAdminSelected } from '../../services/Tickets';
import { useAuth } from '../../context/AuthContext';

const TicketsChat = () => {
  const {ticket_id} = useParams()
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);
  const {user} = useSelector((state) => state.user)
  const {token} = useAuth()
  const navigate = useNavigate()
  const [chat, setChat] = useState([]);
  const axiosInstance = useAxiosWithLoading();

  const fetchChat = async () => {
    const fetchedChat = await getChatMessage({ token, id: ticket_id, axiosInstance });
    setChat(fetchedChat)
  };

  const handleClose = () => {
    closeTicket({
      token,
      id: ticket_id,
      navigate,
      axiosInstance
    })
  }

  useEffect(() => {

    fetchChat()
    const params = { room: ticket_id, user_id: user.id };

    socketRef.current = io(Socket, {
      auth: {
        user_id: user.id,
        serverOffset: 0,
        chat_id: ticket_id,
      },
    });

    socketRef.current.emit('join', params, (err) => {
      if (err) {
        alert(err);
      }
    });

    socketRef.current.on('chatMessage', (msg, serverOffset, user) => {
      setMessages((prevMessages) => [...prevMessages, { message: msg, user }]);
      socketRef.current.auth.serverOffset = serverOffset;
      scrollToBottom();
    });



    return () => {
      socketRef.current.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    if (input) {
      const userId = user.id.toString();
      const chatId = ticket_id;
      socketRef.current.emit('chatMessage', input, userId, chatId);
      setInput('');
    }
    if (!chat.admin_id){
      TicketAdminSelected({
        token,
        data: {
          "admin_id": user.id,
          "chat_id": chat.id
        },
        axiosInstance
      })
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="flex flex-1 justify-between flex-col">
      <div className="flex flex-row justify-between items-center bg-primary px-8 py-4">

        <div className="flex flex-col gap-2">
          <p className="text-2xl font-medium text-white">Problema: {chat?.description}</p>
          <p className="text-base font-medium text-white">Numero de cuenta: {chat.user_id} - {chat?.createdAt?.slice(0,10)}</p>
        </div>
        {
          chat.status && (
            <div className='w-fit h-fit'>
              <ButtonRedBig text={"Cerrar Ticket"} onClick={handleClose} />
            </div>
          )
        }
      </div>
      <div className="flex flex-col h-[70vh] py-8 px-4 w-full gap-2 overflow-y-auto">
        <div className={`flex flex-col gap-2 items-start`}>
          <div className={`bg-primary w-[35%] p-4 pb-8 rounded-lg rounded-bl-none`}>
            <p className="text-white font-semibold text-base">{chat.content}</p>
          </div>
        </div>
        {messages.map((message, index) => (
          <div key={index} className={`flex flex-col gap-2 ${message.user == user.id ? 'items-end' : 'items-start'}`}>
            <div className={`${message.user == user.id ? 'bg-black' : 'bg-primary'} w-[35%] p-4 pb-8 rounded-lg ${message.user == user.id ? 'rounded-br-none' : 'rounded-bl-none'}`}>
              <p className="text-white font-semibold text-base">{message.message}</p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      {
        chat.status && (
          <div className="flex flex-row items-center gap-8 bg-primary px-8 py-4">
          <textarea
            className="w-full px-4 py-2 rounded-lg max-h-24"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button onClick={handleSubmit} className="bg-white px-8 py-2 rounded-lg h-fit hover:bg-opacity-80 transition-all duration-300">Enviar</button>
        </div>
        )
      }
    </div>
  );
};

export default TicketsChat;
