import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Settings from './pages/Settings/Settings';
import Sidebar from './components/Sidebar';
import RealEstate from './pages/RealEstate/RealEstate';
import Tickets from './pages/Tickets/Tickets';
import Professionals from './pages/Professionals/Professionals';
import Documentation from './pages/Documentation/Documentation';
import SelectListUser from './components/SelectListUser';
import { CommunitiesList, CommunityInfo } from './pages/Communities/Communities';
import Information from './pages/Communities/subpages/Information/Information';
import Owners, { OwnerInfo } from './pages/Communities/subpages/Owners/Owners';
import RecordReports from './pages/Communities/subpages/RecordReports/RecordReports';
import Votes from './pages/Communities/subpages/Votes/Votes';
import CommonAreas from './pages/Communities/subpages/CommonAreas/CommonAreas';
import Receipts from './pages/Communities/subpages/Receipts/Receipts';
import Extracts from './pages/Communities/subpages/Extracts/Extracts';
import { EmployeeInfo, EmployeesList } from './pages/Employees/Employees';
import { EmployeeDocuments, EmployeeInformation, EmployeeInteractions, EmployeeTickets } from './pages/Employees/subpages/Employee';
import TicketsChat from './pages/Tickets/TicketsChat';
import AddEmployee from './pages/Employees/subpages/AddEmployee';
import AddRealEstate from './pages/RealEstate/subpages/AddRealEstate';
import RealStateInfo from './pages/RealEstate/subpages/RealEstateInfo';
import AddProfessional from './pages/Professionals/subpages/AddProfessional';
import ProfessionalList from './pages/Professionals/subpages/ProfessionalList';
import ManageProfessional from './pages/Professionals/subpages/ManageProfessional';
import NewOwnerSelection from './pages/Communities/subpages/Owners/NewOwnerSelection';
import NewOwnerManual from './pages/Communities/subpages/Owners/NewOwnerManual';
import OwnerTickets from './pages/Communities/subpages/Owners/OwnerTickets';
import OwnerInformation from './pages/Communities/subpages/Owners/OwnerInformation';
import OwnerReceipts from './pages/Communities/subpages/Owners/OwnerReceipts';
import OwnerExtracts from './pages/Communities/subpages/Owners/OwnerExtracts';
import OwnerSettings from './pages/Communities/subpages/Owners/OwnerSettings';
import OwnerProperties from './pages/Communities/subpages/Owners/OwnerProperties';
import AddCommunity from './pages/Communities/subpages/Home/AddCommunity';
import ManageRecordReport from './pages/Communities/subpages/RecordReports/ManageRecordReport';
import NewRecordReport from './pages/Communities/subpages/RecordReports/NewRecordReport';
import VotesQuestions from './pages/Communities/subpages/Votes/subpages/VotesQuestions';
import VotesResponds from './pages/Communities/subpages/Votes/subpages/VotesResponds';
import NewExtractManual from './pages/Communities/subpages/Extracts/subpages/NewExtractManual';
import Login from './pages/Login/Login';
import NewVote from './pages/Communities/subpages/Votes/subpages/NewVote';
import ManageCommonArea from './pages/Communities/subpages/CommonAreas/subpages/ManageCommonArea';
import NewCommonArea from './pages/Communities/subpages/CommonAreas/subpages/NewCommonArea';
import { useAuth } from './context/AuthContext';
import { useEffect } from 'react';
import { getUserProfile } from './services/User';
import { useDispatch } from 'react-redux';
import NewReceiptManual from './pages/Communities/subpages/Receipts/subpages/NewReceiptManual';
import NewReceiptsExcel from './pages/Communities/subpages/Receipts/subpages/NewReceiptExcel';
import NewExtractExcel from './pages/Communities/subpages/Extracts/subpages/NewExtractExcel';
import NewOwnerExcel from './pages/Communities/subpages/Owners/NewOwnerExcel';
import NotFound from './components/NotFound';
import ViewExtract from './pages/Communities/subpages/Extracts/subpages/ViewExtract';
import ViewReceipt from './pages/Communities/subpages/Receipts/subpages/ViewReceipt';
import ViewDocument from './pages/Employees/subpages/ViewDocuments';
import ViewInteractions from './pages/Employees/subpages/ViewInteractions';
import { useAxiosWithLoading } from './services/config/config';

const App = () => {

  const axiosInstance = useAxiosWithLoading();
  const {token} = useAuth()
  const dispatch = useDispatch()
  useEffect(() => {
    getUserProfile({
      token,
      dispatch,
      axiosInstance
    })
    // eslint-disable-next-line
  },[])


  return (
      <div style={{width: "100vw", height: "100vh", fontFamily: "Poppins"}}>
      {
        !token ? 
        <Login />
        :
        <div className=' className="relative flex flex-row" '>
          <Sidebar />
          <div className="flex flex-col ml-[304px] overflow-x-hidden p-16 py-8 flex-1 bg-white gap-4">
            <SelectListUser />
            <Routes>


              <Route index path="/" element={<Dashboard />} />


              <Route path="/communities" >
                <Route index element={<CommunitiesList />} />
                <Route path="new-community" element={<AddCommunity />} />
                <Route path=":community_id" element={<CommunityInfo />}>
                  <Route index element={<Information />} />

                  <Route path="owners" >
                    <Route index element={<Owners />} />
                    <Route path=':owner_id' element={<OwnerInfo />} >
                      <Route index element={<OwnerInformation />} />
                      <Route path='tickets' >
                        <Route index element={<OwnerTickets />} />
                        <Route path=':ticket_id' element={<TicketsChat />} />
                      </Route>
                      <Route path='receipts' >
                        <Route index element={<OwnerReceipts />} />
                        <Route path=':data' element={<ViewReceipt />} />
                      </Route>
                      <Route path='extracts' >
                        <Route index element={<OwnerExtracts />}  />
                        <Route path=':data' element={<ViewExtract />} />
                      </Route>
                      <Route path='properties' element={<OwnerProperties />} />
                      <Route path='settings' element={<OwnerSettings/>} />
                    </Route>
                    <Route path='new-owner' >
                      <Route index element={<NewOwnerSelection />} />
                      <Route path='manual' element={<NewOwnerManual />} />
                      <Route path='excel' element={<NewOwnerExcel />} />
                    </Route>
                  </Route>

                  <Route path="records-reports" >
                    <Route index element={<RecordReports />} />
                    <Route path=':report_id' element={<ManageRecordReport />} />
                    <Route path='new-record-report' element={<NewRecordReport />} />
                  </Route>
                  
                  <Route path="votes" >
                    <Route index element={<Votes />} />
                    <Route path='new-vote' element={<NewVote />} />
                    <Route path=':vote_id'>
                      <Route index element={<VotesQuestions />} />
                      <Route path=':question_id' element={<VotesResponds />} />
                    </Route>
                  </Route>
                  
                  <Route path="common-areas" >
                    <Route index element={<CommonAreas />} />
                    <Route path=':common_area_id' element={<ManageCommonArea />} />
                    <Route path='new-common-area' element={<NewCommonArea />} />
                  </Route>
                  
                  <Route path="receipts"  >
                    <Route index element={<Receipts />} />
                    <Route path='new-receipts-manual' element={<NewReceiptManual />} />
                    <Route path='new-receipts-excel' element={<NewReceiptsExcel />} />
                    <Route path=':data' element={<ViewReceipt />} />
                  </Route>
                  
                  <Route path="extracts" >
                    <Route index element={<Extracts />} />
                    <Route path='new-extract-manual' element={<NewExtractManual />} />
                    <Route path='new-extract-excel' element={<NewExtractExcel />} />
                    <Route path=':data' element={<ViewExtract />} />
                  </Route>

                </Route>
              </Route>


              <Route path="/employees" >
                <Route index element={<EmployeesList />} />
                <Route path=':employee_id' element={<EmployeeInfo />}>
                  <Route index element={<EmployeeInformation />} />
                  <Route path="tickets" >
                    <Route index element={<EmployeeTickets />} />
                    <Route path=':ticket_id' element={<TicketsChat />} />
                  </Route>
                  <Route path="documents">
                    <Route index element={<EmployeeDocuments />} />
                    <Route path=':type' element={<ViewDocument />} />
                  </Route>
                  <Route path="interactions" >
                    <Route index element={<EmployeeInteractions />} />
                    <Route path=':type' >
                      <Route index element={<ViewInteractions />} />
                      <Route path=':question_id' element={<VotesResponds />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="new-employee" element={<AddEmployee />} />
              </Route>


              <Route path="/real-estate">
                <Route index element={<RealEstate />} />
                <Route path=':property_id' element={<RealStateInfo />} />
                <Route path='new-propertie' element={<AddRealEstate />} />
              </Route>


              <Route path="/tickets">
                <Route index element={<Tickets />} />
                <Route path=':ticket_id' element={<TicketsChat />} />
              </Route>


              <Route path="/professionals" >
                <Route index element={<Professionals />} />
                <Route path='new-professional' element={<AddProfessional />} />
                <Route path=':profession' >
                  <Route index  element={<ProfessionalList />} />
                  <Route path=':professional_id' element={<ManageProfessional />} />
                </Route>
              </Route>


              <Route path="/settings" element={<Settings />} />


              <Route path="/documentation" element={<Documentation />} />

              <Route path="*" element={<NotFound />} />

            </Routes>
          </div>
        </div>
      }
    </div>
  );
};

export default App;
