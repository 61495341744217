const NotFound = () => {
  return (
    <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
      <h1 className="text-8xl font-bold text-highligth">Error 404</h1>
      <p className="text-2xl font-semibold text-black">La página que buscas no existe.</p>
    </div>
  );
}

export default NotFound;
