import { useNavigate } from "react-router-dom";

const NewOwnerSelection = () => {

    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };

    return(
        <div className="flex flex-1 flex-col gap-10">
            <p className="text-2xl font-medium">Registra un Nuevo Propietario</p>
            <div className="flex h-[70vh] items-center flex-row justify-center flex-wrap gap-8">
                <button className="w-[40%] h-40 bg-highligth text-white rounded-lg hover:bg-hoverhighligth duration-300 transition-all" onClick={() => handleClick("./excel")}>Cargar desde excel</button>
                <button className="w-[40%] h-40 bg-primary text-white rounded-lg hover:bg-hover duration-300 transition-all" onClick={() => handleClick("./manual")}>Crear de forma manual</button>
            </div>
        </div>
    )
}

export default NewOwnerSelection;