import React, { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { downloadExtract } from '../../../services/Extracts';
import { downloadReceipt } from '../../../services/Receipts';
import { useAxiosWithLoading } from '../../../services/config/config';

const PdfViewer = ({ pdfData }) => {
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        const loadPdf = async () => {
        const pdfDoc = await PDFDocument.load(pdfData);
        const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });
        setPdfUrl(pdfBytes);
        };
        // eslint-disable-next-lin
        if (pdfData) {
        loadPdf();
        }
    }, [pdfData]);

    return (
        <div>
        {
            pdfUrl ? (<iframe src={pdfUrl} title='pdf' width="100%" height="800px" frameBorder="0" ></iframe>
            ) : (
            <p>No se pudo cargar el PDF.</p>
            )
        }
        </div>
    );
};

const ViewDocument = () => {
    const [pdfData, setPdfData] = useState();
    const {token} = useAuth()
    const {type} = useParams()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const owner = queryParams.get('owner');
    const community = queryParams.get('community');
    const starting = queryParams.get('starting');
    const end = queryParams.get('end');
    const report = queryParams.get('report');
    const axiosInstance = useAxiosWithLoading();

    const fetchDocument = async () => {
        let fetchedDocument;
        if (type === "extract") {
            fetchedDocument = await downloadExtract({ 
                token,
                data: {
                    starting_period: starting,
                    end_period: end,
                    community_id: community,
                    owner_id: owner
                },
                axiosInstance
            });
        } else if (type === "receipt") {
            fetchedDocument = await downloadReceipt({ 
                token,
                owner_id: owner,
                data: {
                    starting_period: starting,
                    end_period: end,
                    community_id: community
                },
                axiosInstance
            });
        }
        setPdfData(fetchedDocument);
    };

    useEffect(() => {
        fetchDocument()
        // eslint-disable-next-line
    }, []);

    return (
        <div>
        {
            type === "report" ? <iframe src={report} width="100%" height="800px" title="Vista previa del PDF" ></iframe> : <PdfViewer pdfData={pdfData} />
        }
        </div>
    );
};

export default ViewDocument;
