import { useState } from 'react';
import { createExtractWithDocs } from '../../../../../services/Extracts';
import {useAuth} from "../../../../../context/AuthContext"
import {useNavigate} from "react-router-dom"
import { useAxiosWithLoading } from '../../../../../services/config/config';

const NewExtractManual = () => {
  
  const {token} = useAuth()
  const navigate = useNavigate()
  const [account, setAccount] = useState('');
  const [community, setCommunity] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [finca, setFinca] = useState('');
  const [rows, setRows] = useState([{ date: '', entry: '', concept: '', debit: '', credit: '', balance: '' }]);

  const handleChangeAccount = (e) => setAccount(e.target.value);
  const handleChangeFinca = (e) => setFinca(e.target.value);
  const handleChangeCommunity = (e) => setCommunity(e.target.value);
  const handleChangeStartDate = (e) => setStartDate(e.target.value);
  const handleChangeEndDate = (e) => setEndDate(e.target.value);

  const addRow = () => {
    setRows([...rows, { date: '', entry: '', concept: '', debit: '', credit: '', balance: '' }]);
  };

  const deleteRow = (index) => {
    setRows(rows.filter((_, rowIndex) => rowIndex !== index));
  };

  const handleChangeRow = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };
  const axiosInstance = useAxiosWithLoading();

  const handleSubmit = (e) => {
    e.preventDefault();
    createExtractWithDocs({
      token,
      navigate,
      data: [
        {
          "community": community,
          "starting_period": startDate,
          "end_period": endDate,
          "finca_number": finca,
          "owner_id": account,
          "extracts": rows
        }
      ],
      axiosInstance
    })
  };

  const disabled = !community || !startDate || !endDate || !finca || !account || !rows[0].date || !rows[0].entry || !rows[0].concept || !rows[0].debit || !rows[0].credit || !rows[0].balance

  return (
    <div className="container mx-auto p-4 overflow-auto">

      <main>
        <form className="space-y-10" onSubmit={handleSubmit}>
          <div className='flex flex-row items-center justify-between'>
            <header className="flex justify-between items-center mb-4">
              <h1 className="text-2xl font-medium">Nuevo extracto</h1>
            </header>
            <button type="submit" disabled={disabled} className={`${disabled ? "disabled:bg-gray-500" : "bg-highligth"} text-white px-4 py-2 rounded-full`}>Enviar extracto</button>
          </div>

          <div className='flex flex-col gap-4'>
            <div className="flex flex-row gap-x-[4%]">
              <div className="flex flex-col gap-4 w-[48%]">
                <label className="text-base font-semibold">Cuenta</label>
                <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={account} onChange={handleChangeAccount} />
              </div>
              <div className="flex flex-col gap-4 w-[48%]">
                <label className="text-base font-semibold">Comunidad</label>
                <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={community} onChange={handleChangeCommunity} />
              </div>
            </div>
            <div className="flex flex-row gap-x-[4%]">
              <div className="flex flex-col gap-4 w-[48%]">
                <label className="text-base font-semibold">Finca</label>
                <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={finca} onChange={handleChangeFinca} />
              </div>
              <div className="flex flex-row gap-x-[4%] w-[48%]">
                <div className="flex flex-col gap-4 w-[48%]">
                  <label className="text-base font-semibold">Periodo Inicio</label>
                  <input type="date" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={startDate} onChange={handleChangeStartDate} />
                </div>
                <div className="flex flex-col gap-4 w-[48%]">
                  <label className="text-base font-semibold">Periodo Fin</label>
                  <input type="date" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={endDate} onChange={handleChangeEndDate} />
                </div>
              </div>
            </div>
          </div>

          <div id="rowsContainer" className='flex flex-col gap-4'>
            {rows.map((row, index) => (
              <div key={index} className="flex flex-row gap-4 items-end gap-x-[2%]">
                <div className="flex flex-col gap-4 w-[15%]">
                  <label className="text-base font-semibold">Fecha</label>
                  <input type="date" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={row.date} onChange={(e) => handleChangeRow(index, 'date', e.target.value)} />
                </div>
                <div className="flex flex-col gap-4 w-[15%]">
                  <label className="text-base font-semibold">Asiento</label>
                  <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={row.entry} onChange={(e) => handleChangeRow(index, 'entry', e.target.value)} />
                </div>
                <div className="flex flex-col gap-4 w-[30%]">
                  <label className="text-base font-semibold">Concepto</label>
                  <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={row.concept} onChange={(e) => handleChangeRow(index, 'concept', e.target.value)} />
                </div>
                <div className="flex flex-col gap-4 w-[10%]">
                  <label className="text-base font-semibold">Debe</label>
                  <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={row.debit} onChange={(e) => handleChangeRow(index, 'debit', e.target.value)} />
                </div>
                <div className="flex flex-col gap-4 w-[10%]">
                  <label className="text-base font-semibold">Haber</label>
                  <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={row.credit} onChange={(e) => handleChangeRow(index, 'credit', e.target.value)} />
                </div>
                <div className="flex flex-col gap-4 w-[10%]">
                  <label className="text-base font-semibold">Saldo</label>
                  <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" value={row.balance} onChange={(e) => handleChangeRow(index, 'balance', e.target.value)} />
                </div>
                <div className="flex items-center space-x-2">
                  <button type="button" className="bg-highligth h-8 w-8 rounded-full text-white" onClick={() => deleteRow(index)}>X</button>
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-row items-center justify-center gap-4'>
            <button type="button" className="bg-primary text-white px-4 py-2 rounded-full" onClick={addRow}>Agregar fila</button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default NewExtractManual;