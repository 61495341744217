import { useEffect, useState } from "react";
import { ListsNoButton } from "../../../../../components/Lists";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import { detailQuestion, QuestionAnswerData } from "../../../../../services/Votes";
import { useAxiosWithLoading } from "../../../../../services/config/config";

const VotesResponds = () => {
    
  const categies = [ "Nombre", "Fecha", "Número de cuenta", "Respuesta"]
  const keys = ['user_election_answer_owner_id.name', 'date', 'user_election_answer_owner_id.id', 'answer_election_answer_answer_id.answer'];

  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const {vote_id, question_id} = useParams()
  const {token} = useAuth()
  const axiosInstance = useAxiosWithLoading();

  const fetchOptions = async () => {
    const fetchedAnswers = await QuestionAnswerData({ token, id: vote_id, axiosInstance });
    Array.isArray(fetchedAnswers) ? setQuestions(fetchedAnswers) : setQuestions([])
  };

  const fetchAnswers = async () => {
    const fetchedAnswers = await detailQuestion({ token, id: question_id, axiosInstance });
    setAnswers(fetchedAnswers)
  };

  useEffect(() => {
    fetchOptions()
    fetchAnswers()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-col gap-10">
      <p className="text-2xl font-medium">{answers.questionName}</p>
      <div className="flex flex-row gap-20">
        {
          questions.map((item, i) => {
            return(
              <div key={i} className="flex flex-col gap-2">
                <p className="text-base font-semibold">Respuesta {i+1}: {item.answer}</p>
                <p className="text-base">Cantidad de votos: {item.quantity_votes}</p>
              </div>
            )
          })
        }
      </div>

      <div className="flex flex-1 flex-col gap-10">
        <ListsNoButton categies={categies} keys={keys} items={answers?.detailQuestion} completeName={true} />
      </div>
    </div>
  )
}

export default VotesResponds;