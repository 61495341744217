export const getCommonZones = async ({ token, community_id, axiosInstance }) => {
  try {
    const response = await axiosInstance.get(`/common-zone/list/common-zones/${community_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

export const obtainOneCommonZone = async ({ token, id, axiosInstance }) => {
  try {
    const response = await axiosInstance.get(`/common-zone/get-one-common-zone/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

export const createCommonZones = async ({ token, data, navigate, id, axiosInstance }) => {
  try {
    await axiosInstance.post(`/common-zone/create-zone/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    navigate("..");
  } catch (error) {
    console.error(error);
  }
};
