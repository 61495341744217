import { useDispatch, useSelector } from "react-redux";
import Card from "./components/Card";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { getDashboard } from "../../services/Communities";
import { useAxiosWithLoading } from "../../services/config/config";
import { getUserProfile } from "../../services/User";

const Dashboard = () => {

    const {token} = useAuth()
    const {user} = useSelector((state) => state.user)
    const [dashboard, setDashboard] = useState([])
    const axiosInstance = useAxiosWithLoading();
    const dispatch = useDispatch()

    const fetchDashboard = async () => {
        const fetchedDashboard = await getDashboard({ token, axiosInstance });
        Array.isArray(fetchedDashboard) ? setDashboard(fetchedDashboard) : setDashboard([])
    };

    useEffect(() => {
        fetchDashboard()
        getUserProfile({
            token,
            dispatch,
            axiosInstance
        })
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">Hola, {user?.name} {user?.lastname}!</p>
            <div className="w-full flex flex-row flex-wrap gap-6">
                {
                    dashboard?.map((item, i) => {
                        return(
                            <Card title={item?.title} number={item?.count} key={i} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Dashboard;