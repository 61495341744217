import { addDotsNumber } from "../../../utilities/AddDotsNumber";

const Card = ({title, number}) => {
    return(
        <div className="flex flex-col rounded-xl gap-2 items-start p-4 w-64 border border-black ">
            <div className="bg-primary w-11 h-11 rounded-full" />
            <p className="text-sm text-subtext">{title}</p>
            <p className="text-2xl">{addDotsNumber(number)}</p>
        </div>
    )
}

export default Card;