export const getReportsByCommunity = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/report/list-report/community/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const createReport = async ({ token, data, navigate, id, axiosInstance }) => {
    try {
        await axiosInstance.post(`/report/create-report/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        });
        navigate("..");
    } catch (error) {
        console.error(error);
    }
};

export const getSpecificReports = async ({ token, data, axiosInstance }) => {
    try {
        const response = await axiosInstance.post(`/report/download-report`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};