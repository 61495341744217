import { useAuth } from "../../../context/AuthContext";
import { ListsNoButton } from "../../../components/Lists";
import { useEffect, useState } from "react";
import { detailVoteCommunity } from "../../../services/Votes";
import { obtainOneCommonZone } from "../../../services/CommonZones";
import { useLocation, useParams } from "react-router-dom";
import { useAxiosWithLoading } from "../../../services/config/config";


const Votes = () => {
  const categies = [ "Pregunta", "Fecha", "Cantidad de opciones"]
  const keys = ['question', 'createDAt', 'answer_quantity'];
  const [questions, setQuestions] = useState([])
  const {token} = useAuth()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vote_id = queryParams.get('vote_id');
  const axiosInstance = useAxiosWithLoading();

  const fetchQuestions = async () => {
    const fetchedQuestions = await detailVoteCommunity({ token, id: vote_id, axiosInstance });
    Array.isArray(fetchedQuestions) ? setQuestions(fetchedQuestions) : setQuestions([])
  };

  useEffect(() => {
      fetchQuestions()
      // eslint-disable-next-line
  },[])
  return(
    <div className="flex flex-1 flex-col gap-10">
      <p className="text-2xl font-medium"></p>
      <ListsNoButton categies={categies} keys={keys} items={questions} />
    </div>
  )
}

const CommonArea = () => {

  const {token} = useAuth()
  const [commonArea, setCommonArea] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const common_area_id = queryParams.get('common_area_id');
  const axiosInstance = useAxiosWithLoading();

  const fetchCommonArea = async () => {
    const fetchedCommonArea = await obtainOneCommonZone({ token, id: common_area_id, axiosInstance });
    setCommonArea(fetchedCommonArea)
  };

  useEffect(() => {
      fetchCommonArea()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-1 flex-col gap-10">
      <p className="text-2xl font-medium">{commonArea?.zone_name} de {commonArea?.community_name}</p>
      <div className="flex flex-row justify-between flex-wrap gap-8">
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Nombre</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{commonArea?.zone_name}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Número de plazas</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{commonArea?.number_places}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Nombre de la comunidad</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{commonArea?.community_name}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Fecha</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{commonArea?.date_started?.slice(0,10)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ViewInteractions = () => {
   
  const {type} = useParams()
  
  return(
    <div className="flex flex-1 flex-col gap-10">
    {
      type === "votes" ? 
      <Votes />
      :
      <CommonArea />
    }
    </div>
  )
}

export default ViewInteractions;