import { useState } from "react"
import { coreAdapter } from "../adapters/core-adapter"
import { useAuth } from "../context/AuthContext"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../redux/states/UserSlice"

const SelectListUser = () => {

    const { select } = coreAdapter()
    const [open, setOpen] = useState(false)
    const { user } = useSelector((state) => state.user)
    const auth = useAuth();
    const dispatch = useDispatch()

    const handleLogout = () => {
        auth.logout();
        dispatch(setUser({}))
    }

    return(
        <div className="relative self-end">
            <button className="flex flex-row justify-between bg-primary w-[250px] p-2 pr-4 rounded-3xl items-center transition-opacity duration-300 hover:opacity-90" onClick={() => setOpen(!open)}>
                <div className="flex flex-row items-center gap-4">
                    <div className="text-start pl-4">
                        <p className="text-sm text-white">{user?.name} {user?.lastname}</p>
                        <p className="text-xs text-white text-opacity-50">{user?.role === "admin" ? "Administrador" : "Empleado"}</p>
                    </div>
                </div>
                <img src={select} className={`${open ? "rotate-180" : ""} transition-rotate duration-500 transform`} alt="select" />
            </button>
            <div className={`${open ? "opacity-100" : "hidden"} absolute flex flex-col gap-2 right-0 border border-black bg-primary rounded-lg mt-1 px-4 py-3 w-full transition-opacity duration-500 transform`}>
                <p className="text-sm text-center text-white">¿Quieres cerrar sesión de tu cuenta?</p>
                <button onClick={handleLogout} className="px-4 py-2 bg-white border border-black w-full rounded-lg hover:bg-white hover:bg-opacity-80 transition-all duration-300 text-highligth text-xs">Cerrar sesión</button>
            </div>
        </div>
    )
}

export default SelectListUser;