const Documentation = () => {

    const itemsList = [

    ]

    return(
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">Documentación</p>
            <div className="flex flex-row flex-wrap gap-8">
                {
                    itemsList.map((item, i) => {
                        return(
                            <div key={i} className="flex flex-col gap-4">
                                <img className="w-80 h-64 bg-sidebar" alt="documento" src={item?.image} />
                                <p className="text-base text-black font-semibold">{item?.text}</p>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default Documentation;