import { useNavigate, useParams } from "react-router-dom";
import { ButtonRedBig } from "../../../../components/Buttons";
import { ListsExtracts } from "../../../../components/Lists";
import SearchBar from "../../../../components/SearchBar";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { getExtractsByCommunity } from "../../../../services/Extracts";
import { useAxiosWithLoading } from "../../../../services/config/config";

const Extracts = () => {

    const categies = [ "Propietario", "Periodo de Inicio", "Periodo de Fin", "Gestionar"]
    const keys = ['owner_id', 'extracts[0].starting_period', 'extracts[0].end_period'];
    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };
    const {community_id} = useParams()
    const {token} = useAuth()
    const [extracts, setExtracts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const axiosInstance = useAxiosWithLoading();

    const fetchExtracts = async () => {
        const fetchedExtracts = await getExtractsByCommunity({ token, id: community_id, axiosInstance });
        Array.isArray(fetchedExtracts) ? setExtracts(fetchedExtracts) : setExtracts([])
        Array.isArray(fetchedExtracts) ? setSearchResults(fetchedExtracts) : setSearchResults([])
    };
    useEffect(() => {
        fetchExtracts()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
                <SearchBar
                    placeholder="Buscar Recibo"
                    data={extracts}
                    searchParams={['owner_id']}
                    onResults={setSearchResults}
                />
                <div className="flex flex-row gap-8">
                    <ButtonRedBig text={"Cargar Extractos por Excel"} onClick={() => handleClick("./new-extract-excel")} />
                    <ButtonRedBig text={"Cargar de Forma Manual"} onClick={() => handleClick("./new-extract-manual")} />
                </div>
            </div>
            <ListsExtracts categies={categies} keys={keys} items={searchResults} path={"extracts"} buttonText={"Gestionar"} />
        </div>
    )
}

export default Extracts;