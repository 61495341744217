import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../../context/AuthContext';
import { createVote } from '../../../../../services/Votes';
import { useAxiosWithLoading } from '../../../../../services/config/config';

const NewVote = () => {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [questions, setQuestions] = useState([{ question: '', options: [''], multiple_options: false }]);
  const {community_id} = useParams()
  const {token} = useAuth()
  const navigate = useNavigate()

  const handleChangeTitle = (e) => setTitle(e.target.value);
  const handleChangeStartDate = (e) => setStartDate(e.target.value);
  const handleChangeEndDate = (e) => setEndDate(e.target.value);

  const addQuestion = () => {
    setQuestions([...questions, { question: '', options: [''], multiple_options: false }]);
  };

  const handleChangeQuestion = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const addAnswer = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].options.push('');
    setQuestions(newQuestions);
  };

  const handleChangeAnswer = (qIndex, aIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[aIndex] = value;
    setQuestions(newQuestions);
  };

  const deleteQuestion = (qIndex) => {
    const newQuestions = questions.filter((_, index) => index !== qIndex);
    setQuestions(newQuestions);
  };

  const deleteAnswer = (qIndex, aIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options = newQuestions[qIndex].options.filter((_, index) => index !== aIndex);
    setQuestions(newQuestions);
  };

  const handleMultipleOptionsChange = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].multiple_options = !newQuestions[index].multiple_options;
    setQuestions(newQuestions);
  };
  const axiosInstance = useAxiosWithLoading();

  const handleSubmit = (e) => {
    e.preventDefault();
    createVote({
      data: {
        community_id,
        name_vote: title,
        date_start: startDate,
        date_end: endDate,
        question_and_answer: questions,
      },
      token,
      navigate,
      axiosInstance
    })
  };

  return (
    <div className="container mx-auto p-4">
      <header className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-medium">Nueva Votación</h1>
      </header>
      <main>
        <form className="space-y-10" onSubmit={handleSubmit}>
          <div className='flex flex-col gap-4'>
            <div className="flex flex-col gap-4 w-[48%] min-w-64">
              <label className="text-base font-semibold">Título de la votación</label>
              <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={title} onChange={handleChangeTitle} />
            </div>
            <div className="flex flex-row gap-x-[2%]">
              <div className="flex flex-col gap-4 w-[23%] min-w-64">
                <label className="text-base font-semibold">Fecha de Inicio</label>
                <input type="date" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={startDate} onChange={handleChangeStartDate} />
              </div>
              <div className="flex flex-col gap-4 w-[23%] min-w-64">
                <label className="text-base font-semibold">Fecha de Fin</label>
                <input type="date" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={endDate} onChange={handleChangeEndDate} />
              </div>
            </div>
          </div>

          <div id="questionsContainer" className='flex flex-row gap-8 flex-wrap'>
            {questions.map((question, qIndex) => (
              <div key={qIndex} className="flex flex-col border border-black px-8 py-4 rounded-3xl gap-4 w-fit h-fit">
                <div className="flex justify-between items-center">
                  <label className="text-base font-semibold">{`Pregunta ${qIndex + 1}`}</label>
                  <div className="flex items-center space-x-2">
                    <button type="button" className="bg-highligth h-8 w-8 rounded-full text-white" onClick={() => deleteQuestion(qIndex)}>X</button>
                  </div>
                </div>
                <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" placeholder="Escribe la pregunta aquí" value={question.question} onChange={(e) => handleChangeQuestion(qIndex, e.target.value)} />
                <div className="flex items-center mb-2 gap-4">
                  <input type="checkbox" id={`multipleOptions${qIndex}`} checked={question.multiple_options} onChange={() => handleMultipleOptionsChange(qIndex)} />
                  <label htmlFor={`multipleOptions${qIndex}`} className="text-base font-semibold">Permitir múltiples opciones</label>
                </div>
                <label className="text-base font-semibold">Respuestas</label>
                {question.options.map((option, aIndex) => (
                  <div key={aIndex} className="flex items-center mb-2 gap-4">
                    <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" placeholder={`Respuesta ${aIndex + 1}`} value={option} onChange={(e) => handleChangeAnswer(qIndex, aIndex, e.target.value)} />
                    <div className="flex items-center space-x-2">
                      <button type="button" className="bg-highligth h-8 w-8 rounded-full text-white" onClick={() => deleteAnswer(qIndex, aIndex)}>X</button>
                    </div>
                  </div>
                ))}
                <button type="button" className="bg-primary text-white px-4 py-2 rounded-full mb-2" onClick={() => addAnswer(qIndex)}>Otra Respuesta</button>
              </div>
            ))}
          </div>
          <div className='flex flex-row items-center justify-center gap-8'>
            <button type="button" className="bg-primary text-white px-4 py-2 rounded-full" onClick={addQuestion}>Generar otra pregunta</button>
            <button type="submit" className="bg-highligth text-white px-4 py-2 rounded-full">Guardar y Enviar</button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default NewVote;
