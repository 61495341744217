export const getDashboard = async ({ token, axiosInstance }) => {
  try {
    const response = await axiosInstance.get('/community/dashboard', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.dashboard;
  } catch (error) {
    console.error(error);
  }
};

export const createCommunity = async ({ token, data, navigate, axiosInstance }) => {
  try {
    await axiosInstance.post('/community/create', {
      name: data.name,
      manager_id: data.manager_id,
      country: data.country,
      province: data.province,
      location: data.location,
      street: data.street,
      postalCode: data.postalCode,
      cif: data.cif,
      ccc: data.ccc
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    navigate("..");
  } catch (error) {
    console.error(error);
  }
};

export const getCommunityById = async ({ token, id, axiosInstance }) => {
  try {
    const response = await axiosInstance.get(`/community/find/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.community;
  } catch (error) {
    console.error(error);
  }
};

export const quantityUsersInCommunity = async ({ token, axiosInstance }) => {
  try {
    const response = await axiosInstance.get('/community/list', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const listOwnersCommunity = async ({ token, id, axiosInstance }) => {
  try {
    const response = await axiosInstance.get(`/community/list/owners/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.owners;
  } catch (error) {
    console.error(error);
  }
};

export const listAllArchiveOwners = async ({ token, id, axiosInstance }) => {
  try {
    const response = await axiosInstance.get(`/community/list/archive/owners/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.owners;
  } catch (error) {
    console.error(error);
  }
};

export const archiveOwnerFromCommunity = async ({ token, owner_id, axiosInstance }) => {
  try {
    await axiosInstance.put(`/community/archive/owners/${owner_id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};

export const unarchivedOwnerFromCommunity = async ({ token, owner_id, community_id, axiosInstance }) => {
  try {
    await axiosInstance.patch(`/community/unarchived-owner/${owner_id}/${community_id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};