import { useEffect, useState } from "react"
import { InputTitle } from "../../../components/Inputs"
import Lists, { ListsDocuments, ListsInteractions } from "../../../components/Lists"
import { allDocument, employeeInteractions, getEmployeeProfile } from "../../../services/Employee"
import { useAuth } from "../../../context/AuthContext"
import { useParams } from "react-router-dom"
import { ticketsForAdmin } from "../../../services/Tickets"
import { FilterTabs } from "../../../components/OptionsTabs"
import { useAxiosWithLoading } from "../../../services/config/config"

export const EmployeeInformation = () => {

    const {token} = useAuth()
    const {employee_id} = useParams()
    const [employee, setEmployee] = useState([])
    const axiosInstance = useAxiosWithLoading();

    const fetchEmployee = async () => {
        const fetchedEmployee = await getEmployeeProfile({ token, id: employee_id, axiosInstance });
        setEmployee(fetchedEmployee) 
    };
    useEffect(() => {
        fetchEmployee()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-10">
            <div className="flex flex-row justify-between flex-wrap gap-8">
                <InputTitle value={employee.name} title={"Nombre"} disabled={true} />
                <InputTitle value={employee.lastname} title={"Apellido"} disabled={true} />
                <InputTitle value={employee.email} title={"Email"} disabled={true} />
                <InputTitle value={employee.phone} title={"Teléfono"} disabled={true} />
            </div>
        </div>      
    )
}

export const EmployeeTickets = () => {

    const categies = [ "Decripción", "Numero de Cuenta", "Fecha", "Estado", "Gestionar"]
    const keys = ['description', 'user_id', 'createdAt', 'status'];
    const axiosInstance = useAxiosWithLoading();

    const {token} = useAuth()
    const {employee_id} = useParams()
    const [tickets, setTickets] = useState([])

    const fetchTickets = async () => {
        const fetchedTickets = await ticketsForAdmin({ token, id:employee_id, axiosInstance });
        Array.isArray(fetchedTickets) ? setTickets(fetchedTickets) : setTickets([])
    };
    useEffect(() => {
        fetchTickets()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-4">
            <Lists categies={categies} keys={keys} items={tickets} path={"tickets"} buttonText={"Acceder"} />
        </div> 
    )
}

export const EmployeeDocuments = () => {

    const [selected, setSelected] = useState("extract")
    const extractCategies = [ "Propietario", "Periodo de inicio", "Periodo de fin", "Gestionar"]
    const receiptCategies = [ "Propietario", "Periodo de inicio", "Periodo de fin", "Gestionar"]
    const reportCategies = [ "Nombre", "Fecha", "Gestionar"]
    const extractKeys = ['owner_id', 'starting_period', 'end_period'];
    const receiptKeys = ['owner_id', 'starting_period', 'end_period'];
    const reportKeys = ['name_minutes', 'createdAt'];
    const {token} = useAuth()
    const {employee_id} = useParams()
    const [documents, setDocuments] = useState({})
    const axiosInstance = useAxiosWithLoading();
    const tabs = [
        {text: "Extractos", value: "extract"},
        {text: "Recibos", value: "receipt"},
        {text: "Acta o Informe", value: "report"},
    ]

    const fetchDocuments= async () => {
        const fetchedDocuments= await allDocument({ token, id: employee_id, axiosInstance });
        setDocuments(fetchedDocuments)
    };
    useEffect(() => {
        fetchDocuments()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-4">
            <FilterTabs tabs={tabs} selected={selected} setSelected={setSelected} />
            <ListsDocuments 
                categies={
                    selected === "receipt" ? receiptCategies :
                    selected === "report" ? reportCategies :
                    selected === "extract" ? extractCategies :
                    extractCategies
                } 
                keys={
                    selected === "receipt" ? receiptKeys :
                    selected === "report" ? reportKeys :
                    selected === "extract" ? extractKeys :
                    extractKeys
                } 
                items={
                    selected === "receipt" ? documents?.receipts :
                    selected === "report" ? documents?.reports :
                    selected === "extract" ? documents?.extracts :
                    documents?.extracts
                }  
                path={"documents"} 
                buttonText={"Acceder"}
                type={selected}
            />
        </div>
    )
}

export const EmployeeInteractions = () => {

    const votesCategies = [ "Nombre", "Fecha de Creacion", "Fecha de Finalización", "Gestionar"]
    const commonZonesCategies = [ "Nombre", "Número de plazas", "Gestionar"]
    const votesKeys = ['name_vote', 'date_start', "date_end"];
    const commonZonesKeys = ['zone_name', 'number_places'];
    
    const [selected, setSelected] = useState("votes")
    const {token} = useAuth()
    const {employee_id} = useParams()
    const [interactions, setInteractions] = useState({})
    const axiosInstance = useAxiosWithLoading();
    const tabs = [
        {text: "Votaciones", value: "votes"},
        {text: "Zonas Comúnes", value: "common-zones"},
    ]

    const fetchInteractions= async () => {
        const fetchedInteractions= await employeeInteractions({ token, id: employee_id, axiosInstance });
        setInteractions(fetchedInteractions)
    };
    useEffect(() => {
        fetchInteractions()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-4">
            <FilterTabs tabs={tabs} selected={selected} setSelected={setSelected} />
            <ListsInteractions 
                categies={
                    selected === "votes" ? votesCategies :
                    selected === "common-zones" ? commonZonesCategies :
                    votesCategies
                } 
                keys={
                    selected === "votes" ? votesKeys :
                    selected === "common-zones" ? commonZonesKeys :
                    votesKeys
                } 
                items={
                    selected === "votes" ? interactions?.votes :
                    selected === "common-zones" ? interactions?.commonZones :
                    interactions?.votes
                }  
                path={"interactions"} 
                buttonText={"Acceder"} 
                type={selected}
            />
        </div>
    )
}