import comunidades from "../assets/svg/comunidades.svg"
import configuración from "../assets/svg/configuración.svg"
import dashboard from "../assets/svg/dashboard.svg"
import documentacion from "../assets/svg/documentacion.svg"
import empleados from "../assets/svg/empleados.svg"
import inmobiliaria from "../assets/svg/inmobiliaria.svg"
import profesionales from "../assets/svg/profesionales.svg"
import tickets from "../assets/svg/tickets.svg"


export const sidebarAdapter = () => {
    const formattedImage = {
        dashboard,
        comunidades,
        empleados,
        inmobiliaria,
        tickets,
        profesionales,
        configuración,
        documentacion
    }

    return formattedImage;
}