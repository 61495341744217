import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { LoadingProvider, useLoading } from './context/LoadingContext';
import { Provider } from 'react-redux';
import store from "./redux/store";
import LoadingOverlay from './components/LoadingOverlay';

const LoadingConsumer = () => {
  const { isLoading } = useLoading();
  return isLoading ? <LoadingOverlay /> : null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <Provider store={store}>
        <LoadingProvider>
          <LoadingConsumer />
          <App />
        </LoadingProvider>
      </Provider>
    </AuthProvider>
  </BrowserRouter>
);
