export const createRealStateProperty = async ({ token, data, navigate, axiosInstance }) => {
    try {
        await axiosInstance.post('/realstate/realstate-create', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        });
        navigate("..");
    } catch (error) {
        console.error(error);
    }
};

export const getAllRealStateProperties = async ({ token, axiosInstance }) => {
    try {
        const response = await axiosInstance.get('/realstate/list-realstate', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.result;
    } catch (error) {
        console.error(error);
    }
};

export const getRealStateById = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/realstate/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};