export const signUpEmployee = async ({ token, data, navigate, axiosInstance }) => {
    try {
        await axiosInstance.post('/employee/new-employee', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        navigate("..");
    } catch (error) {
        console.error(error);
    }
};

export const getEmployees = async ({ token, axiosInstance }) => {
    try {
        const response = await axiosInstance.get('/employee/employees', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const getEmployeeProfile = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/employee/specific-employee/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const allDocument = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/employee/all-document-uploaded/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const employeeInteractions = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/employee/employee-interactions/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};