import { useParams } from "react-router-dom"
import {useAuth} from "../../../../../context/AuthContext"
import { useEffect, useState } from "react"
import { obtainOneCommonZone } from "../../../../../services/CommonZones"
import { useAxiosWithLoading } from "../../../../../services/config/config"


const ManageCommonArea = () => {

  const {common_area_id} = useParams()
  const {token} = useAuth()
  const [commonArea, setCommonArea] = useState([]);
  const axiosInstance = useAxiosWithLoading();

  const fetchCommonArea = async () => {
    const fetchedCommonArea = await obtainOneCommonZone({ token, id: common_area_id, axiosInstance});
    setCommonArea(fetchedCommonArea)
  };

  useEffect(() => {
      fetchCommonArea()
      // eslint-disable-next-line
  },[])

  return(
    <div className="flex flex-1 flex-col gap-10">
      <p className="text-2xl font-medium">{commonArea.zone_name} de {commonArea.community_name}</p>
      <div className="flex flex-row justify-between flex-wrap gap-8">
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Nombre</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{commonArea.zone_name}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Número de plazas</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{commonArea.number_places}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Nombre de la comunidad</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{commonArea.community_name}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
          <h4 className="text-base font-semibold">Fecha</h4>
          <div className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg">
            <p>{commonArea?.date_started?.slice(0,10)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageCommonArea;