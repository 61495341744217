import logo from "../assets/images/logo.png"
import select from "../assets/svg/select.svg"
import search from "../assets/svg/search.svg"


export const coreAdapter = () => {
    const formattedImage = {
        logo,
        select,
        search
    }

    return formattedImage;
}