import { useNavigate, useParams } from "react-router-dom";
import { ButtonRedBig } from "../../../../components/Buttons";
import Lists from "../../../../components/Lists";
import SearchBar from "../../../../components/SearchBar";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import { listVotesCommunities } from "../../../../services/Votes";
import { useAxiosWithLoading } from "../../../../services/config/config";

const Votes = () => {

    const {token} = useAuth()
    const { community_id } = useParams();
    const [votes, setVotes] = useState([])
    const [searchResults, setSearchResults] = useState([]);
    const categies = [ "Nombre", "Fecha de Creacion", "Fecha de Finalización", "Gestionar"]
    const keys = ['name_vote', 'date_start', "date_end"];
    const axiosInstance = useAxiosWithLoading();

    const fetchReports = async () => {
        const fetchedVotes = await listVotesCommunities({ token, id: community_id, axiosInstance });
        Array.isArray(fetchedVotes) ? setVotes(fetchedVotes) : setVotes([])
        Array.isArray(fetchedVotes) ? setSearchResults(fetchedVotes) : setSearchResults([])
    };

    useEffect(() => {
        fetchReports()
        // eslint-disable-next-line
    },[])

    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };

    return(
        <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
            <SearchBar
                    placeholder="Buscar Votación"
                    data={votes}
                    searchParams={['name_vote']}
                    onResults={setSearchResults}
                />
                <ButtonRedBig text={"Nueva Votación"} onClick={() => handleClick("./new-vote")} />
            </div>
            <Lists categies={categies} keys={keys} items={searchResults} path={"votes"} buttonText={"Gestionar"} />
        </div>
    )
}

export default Votes;