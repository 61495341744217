import { createRef, useState } from "react";
import { ButtonBlueBig, ButtonRedBig } from "../../../../components/Buttons";
import { InputTitle } from "../../../../components/Inputs";
import { useFieldInputs } from "../../../../hooks/useField";
import { createReport } from "../../../../services/Reports";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useSelector } from "react-redux";
import { useAxiosWithLoading } from "../../../../services/config/config";

const NewRecordReport = () => {
    const name = useFieldInputs({ title: "Nombre de la comunidad", defaultValue: "" });
    const [selectedFile, setSelectedFile] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const { token } = useAuth();
    const { community_id } = useParams();
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    
    const fileInputRef = createRef();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf") {
            const url = URL.createObjectURL(file);
            setPdfUrl(url);
            setSelectedFile(file);
        } else {
            alert("Por favor, selecciona un archivo PDF.");
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const axiosInstance = useAxiosWithLoading();

    const handleSubmit = async () => {
        if (!selectedFile) {
            alert("Por favor, selecciona un archivo PDF.");
            return;
        }

        const formData = new FormData();
        formData.append("employee_id", user.id);
        formData.append("name_minutes", name.value);
        formData.append("document", selectedFile);

        await createReport({ token, data: formData, navigate, id: community_id, axiosInstance });
    };

    return (
        <div className="flex flex-1 flex-col gap-10">
            <p className="text-2xl font-medium">Nuevo Acta o Informe</p>
            <div className="flex flex-row justify-between items-center flex-wrap gap-8">
                <InputTitle {...name} />
                <ButtonRedBig text={"Publicar"} onClick={handleSubmit} />
            </div>
            <div className="flex flex-row justify-start items-center flex-wrap gap-8">
                <div className="flex flex-row gap-4 items-center">
                    <input 
                        type="file" 
                        accept="application/pdf" 
                        ref={fileInputRef} 
                        style={{ display: 'none' }} 
                        onChange={handleFileChange} 
                    />
                    <ButtonBlueBig text={"Resubir Archivo"} onClick={handleButtonClick} />
                    <p className="py-2 px-4 border border-black rounded-md">
                        {selectedFile ? selectedFile.name : "Selecciona un archivo"}
                    </p>
                </div>
            </div>
            {pdfUrl && (
                <iframe 
                    src={pdfUrl} 
                    width="100%" 
                    height="800px" 
                    title="Vista previa del PDF" 
                ></iframe>
            )}
        </div>
    );
};

export default NewRecordReport;
