import { useState } from "react"
import SelectList from "../../../components/SelectList"
import { InputTitle } from "../../../components/Inputs"
import { useFieldInputs } from "../../../hooks/useField"
import { ButtonRedBig } from "../../../components/Buttons"
import { createProfessional } from "../../../services/Professionals"
import { useAuth } from "../../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useAxiosWithLoading } from "../../../services/config/config"

const AddProfessional = () => {

    const name = useFieldInputs({title: "Nombre", defaultValue: ""})
    const lastname = useFieldInputs({title: "Apellidos", defaultValue: ""})
    const email = useFieldInputs({title: "Email", defaultValue: ""})
    const phone = useFieldInputs({title: "Teléfono", defaultValue: ""})
    const address = useFieldInputs({title: "Dirección", defaultValue: ""})
    const {token} = useAuth()
    const {user} = useSelector((state) => state.user)
    const navigate = useNavigate()
    const [profession, setProfession] = useState('');
    const axiosInstance = useAxiosWithLoading();

    const optionList = [
        {text: "Albañiles", value: "ALBANIL"},
        {text: "Carpinteros", value: "CARPINTERO"},
        {text: "Cerrajeros", value: "CERRAJEROS"},
        {text: "Control de Plagas", value: "CONTROL_PLAGAS"},
        {text: "Electricistas", value: "ELECTRICISTA"},
        {text: "Fontaneros y Desatascador", value: "FONTANERO_DESATASCADOR"},
        {text: "Jardineros", value: "JARDINERO"},
        {text: "Pintores", value: "PINTOR"},
        {text: "Sistemas de Seguridad", value: "SISTEMA_SEGURIDAD"},
        {text: "Técnicos de Aire Acondicionado", value: "TECNICO_AIRE"},
        {text: "Técnicos de Electrodomésticos", value: "TECNICO_ELEC"},
    ]

    const handleSubmit = () => {
        createProfessional({
            data: {
                category: profession,
                email: email.value,
                name: name.value,
                lastname: lastname.value,
                address: address.value,
                employee_name: user.name,
                phone: phone.value
            },
            token,
            navigate,
            axiosInstance
        })
    }

    const disabled = profession && email.value && name.value && lastname.value && address.value && phone.value ? false : true

    return(
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">Agregar nuevo profesional</p>
            <div className="flex flex-row justify-between flex-wrap gap-8">
                <InputTitle {...name} />
                <InputTitle {...lastname} />
                <SelectList options={optionList} title={"Profesión"} placeholder={"Selecciona o Escribe la Profesión"} selectedOption={profession} setSelectedOption={setProfession} />
                <InputTitle {...email} />
                <InputTitle {...phone} />
                <InputTitle {...address} />
            </div>
            <ButtonRedBig text={"Guardar"} disabled={disabled} onClick={handleSubmit} />
        </div>
    )
}

export default AddProfessional;