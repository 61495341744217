import { useParams } from "react-router-dom";
import Lists from "../../../components/Lists";
import SearchBar from "../../../components/SearchBar";
import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import { getProfessionalByCategory } from "../../../services/Professionals";
import { useAxiosWithLoading } from "../../../services/config/config";


const ProfessionalList = () => {
    
    const {profession} = useParams()
    const categies = [ "Nombre", "Teléfono", "Email", "Gestionar"]
    const keys = ['name', 'phone', 'email'];
    const {token} = useAuth()
    const [searchResults, setSearchResults] = useState([]);
    const [professionals, setProfessionals] = useState([])
    const axiosInstance = useAxiosWithLoading();

    const titles = {
        ALBANIL: "Albañiles",
        CARPINTERO: "Carpinteros",
        CERRAJEROS: "Cerrajeros",
        CONTROL_PLAGAS: "Control de Plagas",
        ELECTRICISTA: "Electricistas",
        FONTANERO_DESATASCADOR: "Fontaneros y Desatascador",
        JARDINERO: "Jardineros",
        PINTOR: "Pintores",
        SISTEMA_SEGURIDAD: "Sistemas de Seguridad",
        TECNICO_AIRE: "Técnicos de Aire Acondicionado",
        TECNICO_ELEC: "Técnicos de Electrodomésticos"
    }

    const fetchProfessionals = async () => {
        const fetchedProfessionals = await getProfessionalByCategory({ token, profession, axiosInstance });
        Array.isArray(fetchedProfessionals) ? setProfessionals(fetchedProfessionals) : setProfessionals([])
        Array.isArray(fetchedProfessionals) ? setSearchResults(fetchedProfessionals) : setSearchResults([])
    };
    useEffect(() => {
        fetchProfessionals()
        // eslint-disable-next-line
    },[])


    return(
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">{titles[profession]}</p>
            <SearchBar
                placeholder="Buscar Profesional"
                data={professionals}
                searchParams={['name', 'lastname']}
                onResults={setSearchResults}
            />
            <Lists categies={categies} keys={keys} items={searchResults} buttonText={"Gestionar"} completeName={true} />
        </div>
    )
}

export default ProfessionalList;