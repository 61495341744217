export const createExtractWithDocs = async ({ token, data, navigate, axiosInstance }) => {
    try {
        await axiosInstance.post('/extract/create-extracts', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        navigate("..");
    } catch (error) {
        console.error(error);
    }
};

export const getExtractsByCommunity = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/extract/list-extract-community/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const downloadExtract = async ({ token, data, axiosInstance }) => {
    try {
        const response = await axiosInstance.post('/extract/download', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener el PDF:', error);
    }
};

export const getExtracts = async ({ token, user_id, community_id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/extract/list/${user_id}/${community_id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.extracts;
    } catch (error) {
        console.error(error);
    }
};