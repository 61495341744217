import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { createReceipt } from '../../../../../services/Receipts';
import { useAuth } from "../../../../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { useAxiosWithLoading } from '../../../../../services/config/config';

const NewReceiptsExcel = () => {
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [endPeriod, setEndPeriod] = useState('');
  const {token} = useAuth()
  const navigate = useNavigate()

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setFileName('');
      return;
    }
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Usamos un objeto temporal para agrupar y combinar las descripciones
      const tempData = {};
      jsonData.forEach(item => {
        const ownerName = item.PersonaNombre.trim();
        const key = `${item.FincaCodigo}-${ownerName}`;
        
        if (!tempData[key]) {
          tempData[key] = {
            finca_number: item.FincaCodigo.toString(),
            owner_name: ownerName,
            header_number: item.Cabecera,
            period_number: item.Periodo,
            domiciled: item.PersonaDomiciliado === "True",
            concept: item.Descripcion,
            amount: item["Total Recibo"],
          };
        } else {
          tempData[key].concept += `; ${item.Descripcion}`;
        }
      });

      // Convertimos el objeto temporal en un array
      const transformedData = Object.values(tempData);

      setData(transformedData);
    };
    reader.readAsBinaryString(file);
  };
  const axiosInstance = useAxiosWithLoading();

  const handleSubmit = (e) => {
    e.preventDefault();
    createReceipt({
      data : {
        end_period: Number(endPeriod),
        receipt: data
      },
      token,
      navigate,
      axiosInstance
    })
  };

  const handleChange = (index, key, value) => {
    const newData = [...data];
    newData[index][key] = value;
    setData(newData);
  };

  return (
    <div className="container mx-auto p-4 overflow-auto">
      <header className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-medium">Nuevos recibos por Excel</h1>
      </header>
      <form className="space-y-10" onSubmit={handleSubmit}>
        <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-row items-center border-2 border-black rounded-full'>
            <input type="file" className="hidden" id="fileInput" onChange={handleFileUpload}/>
            <label htmlFor="fileInput" className="bg-primary text-white py-2 px-11 rounded-full hover:bg-opacity-80 transition-all duration-300 cursor-pointer">Subir archivo</label>
          <div className='py-1.5 px-8 text-center'>{fileName ? fileName : "Seleccionar un archivo"}</div>
          </div>
            <button disabled={data.length && endPeriod ? false : true} type="submit" className="bg-highligth text-white disabled:bg-gray-500 px-4 py-2 rounded-full hover:bg-hoverhighligth duration-300 transition-all">Enviar recibos</button>
          </div>
          <div className="flex flex-col gap-4 w-[30%]">
            <label className="text-base font-semibold">Dias a terminar el periodo</label>
            <input type="number" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" value={endPeriod} onChange={(e) => setEndPeriod(e.target.value)} />
          </div>
          <div className='border-t-2 border-black'>
            {data.map((item, index) => (
              <Form
              key={index}
              data={item}
              onChange={(key, value) => handleChange(index, key, value)}
              />
            ))}
          </div>
      </form>
    </div>
  );
};

const Form = ({ data, onChange }) => {
  const { finca_number, owner_name, header_number, period_number, domiciled, concept, amount } = data;

  return (
    <div className='flex flex-col gap-4 border-b-2 border-black py-8'>
      <div className="flex flex-row gap-x-[4%]">
        <div className="flex flex-col gap-4 w-[48%]">
          <label className="text-base font-semibold">Finca</label>
          <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={finca_number} onChange={(e) => onChange('finca_number', e.target.value)} />
        </div>
        <div className="flex flex-col gap-4 w-[48%]">
          <label className="text-base font-semibold">Persona</label>
          <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={owner_name} onChange={(e) => onChange('owner_name', e.target.value)} />
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <label className="text-base font-semibold">Domiciliado</label>
        <input type="checkbox" checked={domiciled} onChange={(e) => onChange('domiciled', e.target.checked)} disabled />
      </div>

      <div id="rowsContainer" className='flex flex-col gap-4'>
        <div className="flex flex-row gap-[2%] items-end">
          <div className="flex flex-col gap-4 w-full">
            <label className="text-base font-semibold">Cabecera</label>
            <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={header_number} onChange={(e) => onChange('header_number', e.target.value)} />
          </div>
          <div className="flex flex-col gap-4 w-full">
            <label className="text-base font-semibold">Periodo</label>
            <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={period_number} onChange={(e) => onChange('period_number', e.target.value)} />
          </div>
          <div className="flex flex-col gap-4 w-full">
            <label className="text-base font-semibold">Descripción</label>
            <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={concept} onChange={(e) => onChange('concept', e.target.value)} />
          </div>
          <div className="flex flex-col gap-4 w-full">
            <label className="text-base font-semibold">Total</label>
            <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={amount} onChange={(e) => onChange('amount', e.target.value)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewReceiptsExcel;