import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { getSpecificReports } from "../../../../services/Reports";
import { useAxiosWithLoading } from "../../../../services/config/config";

const ManageRecordReport = () => {
  const { token } = useAuth();
  const {report_id} = useParams()
  const [report, setReport] = useState({});
  const axiosInstance = useAxiosWithLoading();

  const fetchReport = async () => {
    const fetchedReport = await getSpecificReports({ token, data: {"report_id": report_id}, axiosInstance});
    setReport(fetchedReport)
  };
  
  useEffect(() => {
    fetchReport()
    // eslint-disable-next-line
  },[])

  return (
    <div className="flex flex-1 flex-col gap-10">
      <p className="text-2xl font-medium">{report?.name_minutes}</p>
      {report?.document && (
        <iframe 
          src={report?.document} 
          width="100%" 
          height="800px" 
          title="Vista previa del PDF" 
        ></iframe>
      )}
    </div>
  );
};

export default ManageRecordReport;