import axios from "axios";
import { useLoading } from "../../context/LoadingContext";

export const Socket = "https://backend.universalproperties.online"

export const useAxiosWithLoading = () => {

  const Url = "https://backend.universalproperties.online/api"

  const { startLoading, stopLoading } = useLoading();
  
  const axiosInstance = axios.create({
    baseURL: Url,
  });
  
  axiosInstance.interceptors.request.use(
    (config) => {
      startLoading();
      return config;
    },
    (error) => {
      stopLoading();
      return Promise.reject(error);
    }
  );
  
  axiosInstance.interceptors.response.use(
    (response) => {
      stopLoading();
      return response;
    },
    (error) => {
      stopLoading();
      return Promise.reject(error);
    }
  );
  
  return axiosInstance;
};
  