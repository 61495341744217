import { ButtonBlue } from "./Buttons";
import archive from "../assets/svg/archive.svg"
import unarchive from "../assets/svg/unarchive.svg"
import _ from 'lodash';
import { useAuth } from "../context/AuthContext";
import { archiveOwnerFromCommunity, unarchivedOwnerFromCommunity } from "../services/Communities";
import { useParams } from "react-router-dom";
import { useAxiosWithLoading } from "../services/config/config";

const Lists = ({categies, keys, items, buttonText, completeName}) => (
    <div className="w-[calc(100vw-432px)] overflow-x-auto">
        <div className="overflow-y-auto max-h-[85vh]">
            <div className="flex flex-row justify-between py-4 px-2 gap-8 border-b-2 border-black">
                {categies?.map((item, i) => (
                    <p className={`${i === 0 ? "min-w-64 text-start" : i === categies?.length - 1 ? "min-w-32 text-end pr-2" : "min-w-56 text-center"} text-base font-semibold`} key={i}>{item}</p>
                ))}
            </div>
            {
                items?.length ?
                items?.map((item, i) => (
                    <div key={i} className="flex flex-row border-b-2 border-black items-center justify-between py-2 px-2 gap-8">
                        {keys.map((key, index) => (
                            <p key={index} className={`${index === 0 ? "min-w-64 text-start" : "min-w-56 text-center"} text-base`}> {
                                completeName && key === "name" ? _.get(item, key) + " " + (_.get(item, "lastname") || _.get(item, "lastName")) : 
                                key === "updatedAt" || key === "createdAt" || key === "createDAt" || key === "date_start" || key === "date_end" || key === "starting_period" || key === "end_period" ? _.get(item, key).slice(0,10) :
                                key === "extracts[0].starting_period" || key === "extracts[0].end_period" ? _.get(item, key).slice(0,10) :
                                key === "status" && item[key] ? "Activo" :  
                                key === "status" && !item[key] ? "Cerrado" :  
                                _.get(item, key)
                            }</p>
                        ))}
                        <div className="flex min-w-32 justify-end">
                            <ButtonBlue to={`./${item.id}`} text={buttonText} />
                        </div>
                    </div>
                ))
                :
                <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
                    <p className="text-2xl font-semibold text-black">No existen elementos</p>
                </div>
            }
        </div>
    </div>
)

export const ListsExtracts = ({categies, keys, items, buttonText}) => (
    <div className="w-[calc(100vw-432px)] overflow-x-auto">
        <div className="overflow-y-auto max-h-[85vh]">
            <div className="flex flex-row justify-between py-4 px-2 gap-8 border-b-2 border-black">
                {categies.map((item, i) => (
                    <p className={`${i === 0 ? "min-w-64 text-start" : i === categies.length - 1 ? "min-w-32 text-end pr-2" : "min-w-56 text-center"} text-base font-semibold`} key={i}>{item}</p>
                ))}
            </div>
            {
                items?.length ?
                items?.map((item, i) => (
                    <div key={i} className="flex flex-row border-b-2 border-black items-center justify-between py-2 px-2 gap-8">
                        {keys.map((key, index) => (
                            <p key={index} className={`${index === 0 ? "min-w-64 text-start" : "min-w-56 text-center"} text-base`}> {
                                key === "extracts[0].starting_period" || key === "extracts[0].end_period" ? _.get(item, key).slice(0,10) :
                                _.get(item, key)
                            }</p>
                        ))}
                        <div className="flex min-w-32 justify-end">
                            <ButtonBlue to={`./${item.owner_id}%${item.extracts[0]?.starting_period.slice(0,10)}%${item.extracts[0]?.end_period.slice(0,10)}`} text={buttonText} />
                        </div>
                    </div>
                ))
                :
                <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
                    <p className="text-2xl font-semibold text-black">No existen elementos</p>
                </div>
            }
        </div>
    </div>
)

export const ListsExtractsOwner = ({categies, keys, items, buttonText}) => {

    const {owner_id} = useParams()

    return(
        <div className="w-[calc(100vw-432px)] overflow-x-auto">
            <div className="overflow-y-auto max-h-[85vh]">
                <div className="flex flex-row justify-between py-4 px-2 gap-8 border-b-2 border-black">
                    {categies.map((item, i) => (
                        <p className={`${i === 0 ? "min-w-64 text-start" : i === categies.length - 1 ? "min-w-32 text-end pr-2" : "min-w-56 text-center"} text-base font-semibold`} key={i}>{item}</p>
                    ))}
                </div>
                {
                    items?.length ?
                    items?.map((item, i) => (
                        <div key={i} className="flex flex-row border-b-2 border-black items-center justify-between py-2 px-2 gap-8">
                            {keys.map((key, index) => (
                                <p key={index} className={`${index === 0 ? "min-w-64 text-start" : "min-w-56 text-center"} text-base`}> {
                                    key === "starting_period" || key === "end_period" ? _.get(item, key).slice(0,10) :
                                    _.get(item, key)
                                }</p>
                            ))}
                            <div className="flex min-w-32 justify-end">
                                <ButtonBlue to={`./${owner_id}%${item.starting_period.slice(0,10)}%${item.end_period.slice(0,10)}`} text={buttonText} />
                            </div>
                        </div>
                    ))
                    :
                    <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
                        <p className="text-2xl font-semibold text-black">No existen elementos</p>
                    </div>
                }
            </div>
        </div>
    )
}

export const ListsReceipts = ({categies, keys, items, buttonText}) => (
    <div className="w-[calc(100vw-432px)] overflow-x-auto">
        <div className="overflow-y-auto max-h-[85vh]">
            <div className="flex flex-row justify-between py-4 px-2 gap-8 border-b-2 border-black">
                {categies.map((item, i) => (
                    <p className={`${i === 0 ? "min-w-64 text-start" : i === categies.length - 1 ? "min-w-32 text-end pr-2" : "min-w-56 text-center"} text-base font-semibold`} key={i}>{item}</p>
                ))}
            </div>
            {
                items?.length ?
                items?.map((item, i) => (
                    <div key={i} className="flex flex-row border-b-2 border-black items-center justify-between py-2 px-2 gap-8">
                        {keys.map((key, index) => (
                            <p key={index} className={`${index === 0 ? "min-w-64 text-start" : "min-w-56 text-center"} text-base`}> {
                                key === "starting_period" || key === "end_period" ? _.get(item, key).slice(0,10) :
                                _.get(item, key)
                            }</p>
                        ))}
                        <div className="flex min-w-32 justify-end">
                            <ButtonBlue to={`./${item.owner_id}%${item?.starting_period.slice(0,10)}%${item?.end_period.slice(0,10)}`} text={buttonText} />
                        </div>
                    </div>
                ))
                :
                <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
                    <p className="text-2xl font-semibold text-black">No existen elementos</p>
                </div>
            }
        </div>
    </div>
)


export const ListsDocuments = ({categies, keys, items, buttonText, type}) => {
    return(
        <div className="w-[calc(100vw-432px)] overflow-x-auto">
            <div className="overflow-y-auto max-h-[85vh]">
                <div className="flex flex-row justify-between py-4 px-2 gap-8 border-b-2 border-black">
                    {categies.map((item, i) => (
                        <p className={`${i === 0 ? "min-w-64 text-start" : i === categies?.length - 1 ? "min-w-32 text-end pr-2" : "min-w-56 text-center"} text-base font-semibold`} key={i}>{item}</p>
                    ))}
                </div>
                {
                    items?.length ?
                    items?.map((item, i) => (
                        <div key={i} className="flex flex-row border-b-2 border-black items-center justify-between py-2 px-2 gap-8">
                            {keys.map((key, index) => (
                                <p key={index} className={`${index === 0 ? "min-w-64 text-start" : "min-w-56 text-center"} text-base`}> {
                                    key === "starting_period" || key === "end_period" || key === "createdAt" ? _.get(item, key).slice(0,10) :
                                    _.get(item, key)
                                }</p>
                            ))}
                            <div className="flex min-w-32 justify-end">
                                <ButtonBlue to={`./${type}`} params={
                                    type === "extract" || type === "receipt" ? new URLSearchParams({ community: item.community_id, owner: item.owner_id, starting: item.starting_period.slice(0,10), end: item.end_period.slice(0,10) }).toString() : 
                                    new URLSearchParams({ report: item.document })
                                } text={buttonText} 
                            />
                            </div>
                        </div>
                    ))
                    :
                    <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
                        <p className="text-2xl font-semibold text-black">No existen elementos</p>
                    </div>
                }
            </div>
        </div>
    )
}

export const ListsInteractions = ({categies, keys, items, buttonText, type}) => {
    return(
        <div className="w-[calc(100vw-432px)] overflow-x-auto">
            <div className="overflow-y-auto max-h-[85vh]">
                <div className="flex flex-row justify-between py-4 px-2 gap-8 border-b-2 border-black">
                    {categies.map((item, i) => (
                        <p className={`${i === 0 ? "min-w-64 text-start" : i === categies?.length - 1 ? "min-w-32 text-end pr-2" : "min-w-56 text-center"} text-base font-semibold`} key={i}>{item}</p>
                    ))}
                </div>
                {
                    items?.length ?
                    items?.map((item, i) => (
                        <div key={i} className="flex flex-row border-b-2 border-black items-center justify-between py-2 px-2 gap-8">
                            {keys.map((key, index) => (
                                <p key={index} className={`${index === 0 ? "min-w-64 text-start" : "min-w-56 text-center"} text-base`}> {
                                    key === "date_start" || key === "date_end" ? _.get(item, key).slice(0,10) :
                                    _.get(item, key)
                                }</p>
                            ))}
                            <div className="flex min-w-32 justify-end">
                                <ButtonBlue to={`./${type}`} params={
                                    type === "votes" ? new URLSearchParams({ vote_id: item.id }).toString() : 
                                    new URLSearchParams({ common_area_id: item.id }).toString() 
                                } text={buttonText} 
                            />
                            </div>
                        </div>
                    ))
                    :
                    <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
                        <p className="text-2xl font-semibold text-black">No existen elementos</p>
                    </div>
                }
            </div>
        </div>
    )
}

export const ListsOwner = ({categies, keys, items, buttonText, archived}) => { 
    
    const {token} = useAuth()
    const {community_id} = useParams()
    const axiosInstance = useAxiosWithLoading();
    const handleArchive = ({owner_id}) => {
        archiveOwnerFromCommunity({
            token,
            owner_id,
            axiosInstance
        })
    }

    const handleUnarchive = ({owner_id}) => {
        unarchivedOwnerFromCommunity({
            token,
            owner_id,
            community_id: community_id,
            axiosInstance
        })
    }

    return (
        <div className="w-[calc(100vw-432px)] overflow-x-auto">
            <div className="overflow-y-auto max-h-[85vh]">
                <div className="flex flex-row justify-between py-4 px-2 gap-8 border-b-2 border-black">
                    {categies.map((item, i) => (
                        <p className={`${i === 0 ? "min-w-72 text-start" : i === categies.length - 1 ? "min-w-36 text-end pr-2" : "min-w-56 text-center"} text-base font-semibold`} key={i}>{item}</p>
                    ))}
                </div>
                {
                    items?.length ?
                    items?.map((item, i) => (
                        <div key={i} className="flex flex-row border-b-2 border-black items-center justify-between py-2 px-2 gap-8">
                            {keys.map((key, index) => (
                                <p key={index} className={`${index === 0 ? "min-w-72 text-start" : "min-w-56 text-center"} text-base`}> { key === "name" ? _.get(item, key) + " " + _.get(item, "lastname") : key === "updatedAt" ? _.get(item, key).slice(0,10) : _.get(item, key)}</p>
                            ))}
                            <div className="flex flex-row gap-4 min-w-36 justify-end">
                                <button onClick={!archived ? () => handleArchive({ owner_id: item.id}) : () => handleUnarchive({ owner_id: item.id})} className={`p-2 rounded-full bg-highligth  hover:bg-opacity-80 transition-all duration-300`}>
                                    <img src={archived ? unarchive : archive} alt="archivar" />
                                </button>
                                <ButtonBlue to={`./${item.id}`} text={buttonText} />
                            </div>
                        </div>
                    ))
                    :
                    <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
                        <p className="text-2xl font-semibold text-black">No existen elementos</p>
                    </div>
                }
            </div>
        </div>
    )
}
export const ListsNoButton = ({categies, keys, items, completeName}) => (
    <div className="w-[calc(100vw-432px)] overflow-x-auto">
        <div className="overflow-y-auto max-h-[85vh]">
            <div className="flex flex-row justify-between py-4 px-2 gap-8 border-b-2 border-black">
                {categies.map((item, i) => (
                    <p className={`${i === 0 ? "min-w-64 text-start" : "min-w-56 text-center"} text-base font-semibold`} key={i}>{item}</p>
                ))}
            </div>
            {
                items?.length ?
                items?.map((item, i) => (
                    <div key={i} className="flex flex-row border-b-2 border-black items-center justify-between py-2 px-2 gap-8">
                        {keys.map((key, index) => (
                            <p key={index} className={`${index === 0 ? "min-w-64 text-start" : "min-w-56 text-center"} text-base`}> {
                                completeName && key === "name" ? _.get(item, key) + " " + (_.get(item, "lastname") || _.get(item, "lastName")) : 
                                key === "updatedAt" || key === "createdAt" || key === "createDAt" || key === "date_start" || key === "date_end" || key === "starting_period" || key === "end_period"|| key === "date" ? _.get(item, key).slice(0,10) :
                                _.get(item, key)
                            }</p>
                        ))}
                    </div>
                ))
                :
                <div className="h-[70vh] flex flex-col items-center justify-center gap-8">
                    <p className="text-2xl font-semibold text-black">No existen elementos</p>
                </div>
            }
        </div>
    </div>
)

export default Lists;