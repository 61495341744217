import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { createExtractWithDocs } from '../../../../../services/Extracts';
import {useAuth} from "../../../../../context/AuthContext"
import {useNavigate} from "react-router-dom"
import { useAxiosWithLoading } from '../../../../../services/config/config';

const SubmitExcelExtract = ({ setJsonData }) => {
  const [fileName, setFileName] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      // Usuario ha cancelado la selección del archivo
      setFileName('');
      return;
    }

    setFileName(file.name);

    const reader = new FileReader();
  
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
  
      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const combinedData = [];
  
      let currentAccount = null;
  
      for (let i = 0; i < sheetData.length; i++) {
        const row = sheetData[i];
  
        for (let j = 0; j < row.length; j++) {
          if (row[j] === 'Comunidad') {
            if (currentAccount) {
              combinedData.push(currentAccount);
            }
            const communityName = sheetData[i + 1][j];
            currentAccount = { community: communityName, extracts: [] };
          }
  
          if (row[j] === 'Período') {
            const starting_period = sheetData[i + 2][j - 1];
            const end_period = sheetData[i + 2][j];
            currentAccount.starting_period = starting_period;
            currentAccount.end_period = end_period;
          }

          if (row[j] === 'Pág.') {
            const finca_number = sheetData[i + 3][j];
            currentAccount.finca_number = finca_number;
          }

          if (row[j] === 'Fecha') {
            let extractRow = i + 1;
            while (sheetData[extractRow] && sheetData[extractRow][0]) {
              const date = sheetData[extractRow][j];
              const entry = sheetData[extractRow][j + 1];
              const concept = sheetData[extractRow][j + 2];
              const debit = sheetData[extractRow][j + 7];
              const credit = sheetData[extractRow][j + 9];
              const balance = sheetData[extractRow][j + 10];

              currentAccount.extracts.push({ date, entry, concept, debit, credit, balance });
              extractRow++;
            }
          }

          if (row[j] === 'CUENTA') {
            const owner_id = sheetData[i][j + 1];
            currentAccount.owner_id = owner_id;
          }
        }
      }
  
      if (currentAccount) {
        combinedData.push(currentAccount);
      }
  
      setJsonData(combinedData);
    };
  
    reader.readAsBinaryString(file);
  };
  
  return (
    <div className='flex flex-row items-center border-2 border-black rounded-full'>
      <input type="file" className="hidden" id="fileInput" onChange={handleFileUpload}/>
      <label htmlFor="fileInput" className="bg-primary text-white py-2 px-11 rounded-full hover:bg-opacity-80 transition-all duration-300 cursor-pointer">Subir archivo</label>
      <div className='py-1.5 px-8 text-center'>{fileName ? fileName : "Seleccionar un archivo"}</div>
    </div>
  );
};


const NewExtractExcel = () => {
  const [accounts, setAccounts] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const {token} = useAuth()
  const navigate = useNavigate()
  const axiosInstance = useAxiosWithLoading();

  useEffect(() => {
    if (jsonData.length > 0) {
      const newAccounts = jsonData.map(accountData => ({
        ...accountData,
        starting_period: convertDateFormat(accountData.starting_period),
        end_period: convertDateFormat(accountData.end_period),
        extracts: accountData.extracts.map(extract => ({
          ...extract,
          date: convertDateFormat(extract.date)
        }))
      }));
      setAccounts(newAccounts);
    }
  }, [jsonData]);

  const convertDateFormat = (date) => {
    if (!date) return '';
    const parts = date.split('/');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year.length === 4 ? year : "20" + year}-${month}-${day}`;
    } else {
      return date;
    }
  };

  const handleChangeAccount = (index, value) => {
    const newAccounts = [...accounts];
    newAccounts[index].owner_id = value;
    setAccounts(newAccounts);
  };

  const handleChangeCommunity = (index, value) => {
    const newAccounts = [...accounts];
    newAccounts[index].community = value;
    setAccounts(newAccounts);
  };

  const handleChangeStartDate = (index, value) => {
    const newAccounts = [...accounts];
    newAccounts[index].starting_period = convertDateFormat(value);
    setAccounts(newAccounts);
  };

  const handleChangeEndDate = (index, value) => {
    const newAccounts = [...accounts];
    newAccounts[index].end_period = convertDateFormat(value);
    setAccounts(newAccounts);
  };

  const handleChangeRow = (accountIndex, rowIndex, field, value) => {
    const newAccounts = [...accounts];
    if (field === 'date') {
      newAccounts[accountIndex].extracts[rowIndex][field] = convertDateFormat(value);
    } else {
      newAccounts[accountIndex][field] = value;
    }
    setAccounts(newAccounts);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createExtractWithDocs({
      data: accounts,
      token,
      navigate,
      axiosInstance
    })
  };

  return (
    <div className="container mx-auto p-4 overflow-auto">
      <header className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-medium">Nuevos extractos por Excel</h1>
      </header>
      <main>
        <form className="space-y-10" onSubmit={handleSubmit}>
          <div className='flex flex-row justify-between items-center'>
            <SubmitExcelExtract setJsonData={setJsonData} />
            <button type="submit" disabled={!jsonData.length} className="bg-highligth text-white px-4 py-2 rounded-full hover:bg-hoverhighligth disabled:bg-gray-500 duration-300 transition-all">Enviar extractos</button>
          </div>

          {accounts.map((account, accountIndex) => (
            <div key={accountIndex} className="space-y-6">
              <div className='flex flex-col gap-4'>
                <div className="flex flex-row gap-x-[4%]">
                  <div className="flex flex-col gap-4 w-[48%]">
                    <label className="text-base font-semibold">Cuenta</label>
                    <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={account.owner_id} onChange={(e) => handleChangeAccount(accountIndex, e.target.value)} />
                  </div>
                  <div className="flex flex-col gap-4 w-[48%]">
                    <label className="text-base font-semibold">Comunidad</label>
                    <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={account.community} onChange={(e) => handleChangeCommunity(accountIndex, e.target.value)} />
                  </div>
                </div>
                <div className="flex flex-row gap-x-[4%]">
                  <div className="flex flex-col gap-4 w-[48%]">
                    <label className="text-base font-semibold">Finca</label>
                    <input type="text" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={account.finca_number} onChange={(e) => handleChangeCommunity(accountIndex, e.target.value)} />
                  </div>
                  <div className="flex flex-row gap-x-[4%] w-[48%]">
                    <div className="flex flex-col gap-4 w-[48%]">
                      <label className="text-base font-semibold">Periodo Inicio</label>
                      <input type="date" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={account.starting_period} onChange={(e) => handleChangeStartDate(accountIndex, e.target.value)} />
                    </div>
                    <div className="flex flex-col gap-4 w-[48%]">
                      <label className="text-base font-semibold">Periodo Fin</label>
                      <input type="date" className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={account.end_period} onChange={(e) => handleChangeEndDate(accountIndex, e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>

              <div id="rowsContainer" className="space-y-4 border-b-2 border-black pb-8 w-full">
                {account.extracts.map((row, rowIndex) => (
                  <div key={rowIndex} className="flex flex-row gap-x-[2%] items-end w-full">
                    <div className="flex flex-col gap-4 w-[15%]">
                      <label className="text-base font-semibold">Fecha</label>
                      <input type="date" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={row.date} onChange={(e) => handleChangeRow(accountIndex, rowIndex, 'date', e.target.value)} />
                    </div>
                    <div className="flex flex-col gap-4 w-[15%]">
                      <label className="text-base font-semibold">Asiento</label>
                      <input type="text" className="flex outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={row.entry} onChange={(e) => handleChangeRow(accountIndex, rowIndex, 'entry', e.target.value)} />
                    </div>
                    <div className="flex flex-col gap-4 w-[30%]">
                      <label className="text-base font-semibold">Concepto</label>
                      <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={row.concept} onChange={(e) => handleChangeRow(accountIndex, rowIndex, 'concept', e.target.value)} />
                    </div>
                    <div className="flex flex-col gap-4 w-[10%]">
                      <label className="text-base font-semibold">Debe</label>
                      <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={row.debit} onChange={(e) => handleChangeRow(accountIndex, rowIndex, 'debit', e.target.value)} />
                    </div>
                    <div className="flex flex-col gap-4 w-[10%]">
                      <label className="text-base font-semibold">Haber</label>
                      <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={row.credit} onChange={(e) => handleChangeRow(accountIndex, rowIndex, 'credit', e.target.value)} />
                    </div>
                    <div className="flex flex-col gap-4 w-[10%]">
                      <label className="text-base font-semibold">Saldo</label>
                      <input type="text" className="flex-1 outline-none px-4 py-1 border-2 border-black rounded-lg" disabled value={row.balance} onChange={(e) => handleChangeRow(accountIndex, rowIndex, 'balance', e.target.value)} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </form>
      </main>
    </div>
  );
};

export default NewExtractExcel;
