import { Link, useLocation } from "react-router-dom"
import { sidebarAdapter } from "../adapters/sidebar-adapter"
import { coreAdapter } from "../adapters/core-adapter"
import {useSelector} from "react-redux"

const Sidebar = () => {

    // eslint-disable-next-line
    const { logo } = coreAdapter()
    const { dashboard, comunidades, empleados, inmobiliaria, tickets, profesionales, configuración } = sidebarAdapter()
    const location = useLocation();
    const path = location.pathname.split('/')[1];
    const {user} = useSelector((state) => state.user)

    const optionList = [
        {text: "Dashboard", icon: dashboard, route: ""},
        {text: "Comunidades", icon: comunidades, route: "communities"},
        {text: "Empleados", icon: empleados, route: "employees"},
        {text: "Inmobiliaria", icon: inmobiliaria, route: "real-estate"},
        {text: "Tickets", icon: tickets, route: "tickets"},
        {text: "Profesionales", icon: profesionales, route: "professionals"},
        {text: "Configuración", icon: configuración, route: "settings"},
    ]

    return(
        <div className="fixed flex flex-col h-full w-fit bg-sidebar py-16 px-8 overflow-auto">
            <img src={logo} alt="logo" className="w-60 h-14 mb-16" />
            <div className="flex w-full flex-col gap-4">
                {
                    optionList.map((item, i) => {
                        return(
                            <Link to={`/${item.route}`} key={i} className={`${item.text === "Empleados" && user?.role !== "admin" ? "hidden" : ""} flex flex-row items-center w-full gap-4 text-highligth rounded-lg py-2 px-4 transition-all duration-300 hover:bg-black hover:bg-opacity-10 ${path === item.route ? "bg-black bg-opacity-5" : ""}`}>
                                <img src={item.icon} alt="svg" />
                                <p className="text-base font-semibold">{item.text}</p>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Sidebar;