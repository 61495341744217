export const allTickets = async ({ token, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/ticket/show-all-tickets`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const allOpenTickets = async ({ token, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/ticket/all-open-tickets`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const allCloseTickets = async ({ token, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/ticket/all-tickets-close`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const ticketsForAdmin = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/ticket/get-tickets-for-admin/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const TicketAdminSelected = async ({ token, data, axiosInstance }) => {
    try {
        await axiosInstance.post(`/ticket/insert-Ticket`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error(error);
    }
};

export const closeTicket = async ({ token, id, navigate, axiosInstance }) => {
    try {
        await axiosInstance.patch(`/ticket/close-ticket/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });
        navigate("..");
    } catch (error) {
        console.error(error);
    }
};

export const getChatMessage = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/ticket/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.chat;
    } catch (error) {
        console.error(error);
    }
};

export const getTickets = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/ticket/get-tickets/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};