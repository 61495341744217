import { useNavigate } from "react-router-dom"
import { ButtonRedBig } from "../../../components/Buttons"
import { InputTitle } from "../../../components/Inputs"
import { useAuth } from "../../../context/AuthContext"
import { useFieldInputs } from "../../../hooks/useField"
import { signUpEmployee } from "../../../services/Employee"
import { useAxiosWithLoading } from "../../../services/config/config"


const AddEmployee = () => {

    const {token} = useAuth()
    const name = useFieldInputs({title: "Nombre", defaultValue: ""})
    const lastname = useFieldInputs({title: "Apellidos", defaultValue: ""})
    const email = useFieldInputs({title: "Email", defaultValue: ""})
    const password = useFieldInputs({title: "Contraseña", defaultValue: ""})
    const phone = useFieldInputs({title: "Teléfono", defaultValue: ""})
    const navigate = useNavigate()
    const axiosInstance = useAxiosWithLoading();

    const handleSubmit = () => {
        signUpEmployee({
            data: {
                name: name.value,
                lastname: lastname.value,
                email: email.value,
                password: password.value,
                phone: phone.value
            },
            token,
            navigate,
            axiosInstance
        })
    }

    const disabled = !name.value && !lastname.value && !email.value && !password.value && !phone.value

    return(
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">Agregar Nuevo Empleado</p>
            <div className="flex flex-row justify-between flex-wrap gap-8">
                <InputTitle {...name} />
                <InputTitle {...lastname} />
                <InputTitle {...email} />
                <InputTitle {...phone} />
                <InputTitle {...password} />
            </div>
            <ButtonRedBig text={"Guardar"} onClick={handleSubmit} disabled={disabled} />
        </div>
    )
}

export default AddEmployee;