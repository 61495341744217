import { useNavigate, useParams } from "react-router-dom";
import { ButtonRedBig } from "../../../../components/Buttons";
import Lists from "../../../../components/Lists";
import SearchBar from "../../../../components/SearchBar";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { getCommonZones } from "../../../../services/CommonZones";
import { useAxiosWithLoading } from "../../../../services/config/config";

const CommonAreas = () => {

    const categies = [ "Nombre", "Número de plazas", "Gestionar"]
    const keys = ['zone_name', 'number_places'];
    const [commonAreas, setCommonAreas] = useState([])
    const [searchResults, setSearchResults] = useState([]);
    const {token} = useAuth()
    const {community_id} = useParams()
    const axiosInstance = useAxiosWithLoading();


    const fetchCommonAreas = async () => {
        const fetchedCommonAreas = await getCommonZones({ token, community_id: community_id, axiosInstance });
        Array.isArray(fetchedCommonAreas) ? setCommonAreas(fetchedCommonAreas) : setCommonAreas([])
        Array.isArray(fetchedCommonAreas) ? setSearchResults(fetchedCommonAreas) : setSearchResults([])
    };
    useEffect(() => {
        fetchCommonAreas()
        // eslint-disable-next-line
    },[])

    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };


    return(
        <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
                <SearchBar
                    placeholder="Buscar Zona Común"
                    data={commonAreas}
                    searchParams={['zone_name']}
                    onResults={setSearchResults}
                />
                <ButtonRedBig text={"Nueva Zona Común"} onClick={() => handleClick("./new-common-area")} />
            </div>
            <Lists categies={categies} keys={keys} items={searchResults} path={"common-areas"} buttonText={"Gestionar"} />
        </div>
    )
}

export default CommonAreas;