import { setUser } from '../redux/states/UserSlice';

export const getUserProfile = async ({ token, dispatch, axiosInstance }) => {
  try {
    const response = await axiosInstance.get(`/user/get-profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = {
      id: response.data.data.id,
      name: response.data.data.name,
      lastname: response.data.data.lastname,
      role: response.data.data.role,
      archived: response.data.data.archived,
      email: response.data.data.emails[0]?.value,
      phone: response.data.data.phones[0]?.value,
    };
    dispatch(setUser(data));
  } catch (error) {
    console.error(error);
  }
};

export const createOwner = async ({ token, data, navigate, community_id, axiosInstance }) => {
  try {
    await axiosInstance.post(`/user/create/owner/${community_id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    navigate("../..");
  } catch (error) {
    console.error(error);
  }
};

export const createMultipleOwners = async ({ token, data, navigate, community_id, axiosInstance }) => {
  try {
    await axiosInstance.post(`/user/create-many-owners/${community_id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    navigate("../..");
  } catch (error) {
    console.error(error);
  }
};

export const getOwnerProfile = async ({ token, id, axiosInstance }) => {
  try {
    const response = await axiosInstance.get(`/user/owner/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

export const getOwnerProperties = async ({ token, id, axiosInstance }) => {
  try {
    const response = await axiosInstance.get(`/user/owner/properties/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateUser = async ({ token, data, navigate, user_id, axiosInstance, setError }) => {
  try {
    await axiosInstance.put(`/user/update/${user_id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setError("")
    navigate("..");
  } catch (error) {
    console.error(error)
  }
};