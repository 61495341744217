import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ButtonRedBig } from "../../components/Buttons";
import Lists from "../../components/Lists";
import OptionsTabs from "../../components/OptionsTabs";
import SearchBar from "../../components/SearchBar";
import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { getEmployeeProfile, getEmployees } from "../../services/Employee";
import { useAxiosWithLoading } from "../../services/config/config";

export const EmployeesList = () => {

    const {token} = useAuth()
    const [employees, setEmployees] = useState([])
    const categies = [ "Nombre", "Numero de Id", "Gestionar"]
    const keys = ['name', 'id'];
    const [searchResults, setSearchResults] = useState([]);
    const axiosInstance = useAxiosWithLoading();

    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };

    const fetchEmployees = async () => {
        const fetchedEmployees = await getEmployees({ token, axiosInstance });
        Array.isArray(fetchedEmployees) ? setEmployees(fetchedEmployees) : setEmployees([])
        Array.isArray(fetchedEmployees) ? setSearchResults(fetchedEmployees) : setSearchResults([])
    };

    useEffect(() => {
        fetchEmployees()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-10">
            <p className="text-2xl font-medium">Listado de Empleados</p>
            <div className="flex flex-row items-center justify-between w-full">
                <SearchBar
                    placeholder="Buscar Empleado"
                    data={employees}
                    searchParams={['name', 'lastName']}
                    onResults={setSearchResults}
                /><ButtonRedBig text={"Nuevo Empleado"} onClick={() => handleClick("./new-employee")} />
            </div>
            <Lists categies={categies} keys={keys} items={searchResults} buttonText={"Gestionar"} completeName={true} />
        </div>
    )
}

export const EmployeeInfo = () => {

    const { employee_id } = useParams();    
    const {token} = useAuth()
    const [employee, setEmployee] = useState([])
    const axiosInstance = useAxiosWithLoading();

    const fetchEmployee = async () => {
        const fetchedEmployee = await getEmployeeProfile({ token, id: employee_id, axiosInstance });
        setEmployee(fetchedEmployee) 
    };
    useEffect(() => {
        fetchEmployee()
        // eslint-disable-next-line
    },[])

    const tabs = [
        {text: "Información", route: employee_id, path: ""},
        {text: "Tickets", route: `tickets`, path: "tickets"},
        {text: "Documentos", route: `documents`, path: "documents"},
        {text: "Interacciones", route: `interactions`, path: "interactions"},
    ]
    const location = useLocation();
    const hideForSubOwners = 
    /\/tickets\/(?!$)/.test(location.pathname) || 
    /\/documents\/(?!$)/.test(location.pathname) ||
    /\/interactions\/(?!$)/.test(location.pathname)



    return(
        <div className="flex flex-col gap-10">
            {!hideForSubOwners && (
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-2">
                        <p className="text-2xl font-medium">{`${employee?.name} ${employee?.lastname}`}</p>
                        <p className="text-base font-medium">Registrado desde {employee?.createdAt?.slice(0,10)}</p>
                    </div>
                    <OptionsTabs tabs={tabs} id={employee_id} />
                </div>
            )}
            <Outlet />
        </div>
    )
}