import electricista from "../assets/svg/electricista.svg"
import jardinero from "../assets/svg/jardinero.svg"
import tecnicosAC from "../assets/svg/tecnicoAC.svg"
import seguridad from "../assets/svg/seguridad.svg"
import pintor from "../assets/svg/pintor.svg"
import plagas from "../assets/svg/plagas.svg"
import cerrajero from "../assets/svg/cerrajero.svg"
import fontanero from "../assets/svg/fontanero.svg"
import carpintero from "../assets/svg/carpintero.svg"
import albañil from "../assets/svg/albañil.svg"
import tecnicoE from "../assets/svg/tecnicoE.svg"


export const proffessionAdapter = () => {
    const formattedImage = {
        electricista,
        jardinero,
        tecnicosAC,
        seguridad,
        pintor,
        plagas,
        cerrajero,
        fontanero,
        carpintero,
        albañil,
        tecnicoE
    }

    return formattedImage;
}