const SelectList = ({ options, title, placeholder, selectedOption, setSelectedOption }) => {

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <div className="flex flex-col gap-4 w-[48%] min-w-64">
            <h4 className="text-base font-semibold">{title}</h4>
            <input
                list="options"
                id="select-list"
                value={selectedOption}
                onChange={handleChange}
                placeholder={placeholder}
                className="w-full outline-none px-4 py-1 border-2 border-black rounded-lg"
            />
            <datalist id="options">
                {options.map((option, index) => (
                <option key={index} value={option.value} >{option.text}</option>
                ))}
            </datalist>
        </div>
    );
};

export default SelectList;
