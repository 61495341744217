import { useNavigate } from "react-router-dom";
import { ButtonRedBig } from "../../components/Buttons";
import Lists from "../../components/Lists";
import { getAllRealStateProperties } from "../../services/RealState";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useAxiosWithLoading } from "../../services/config/config";

const RealEstate = () => {
    
    const {token} = useAuth()
    const categies = [ "Nombre", "Dirección", "Tipo de propiedad", "Alquiler/Compra", "Gestionar"]
    const keys = ['property_name', 'address', 'property_type', 'rental'];
    const [realState, setRealState] = useState([]);
    const axiosInstance = useAxiosWithLoading();

    const fetchRealState = async () => {
        const fetchedRealState = await getAllRealStateProperties({ token, axiosInstance });
        Array.isArray(fetchedRealState) ? setRealState(fetchedRealState) : setRealState([])
    };
    const navigate = useNavigate();
    const handleClick = (route) => {
        navigate(route);
    };
    useEffect(() => {
        fetchRealState()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-10">
            <div className="flex flex-row items-center justify-between w-full">
                <p className="text-2xl font-medium">Propiedades</p>
                <ButtonRedBig text={"Nueva Propiedad"} onClick={() => handleClick("./new-propertie")} />
            </div>
            <Lists categies={categies} keys={keys} items={realState} buttonText={"Gestionar"} />
        </div>
    )
}

export default RealEstate;