import { useNavigate, useParams } from "react-router-dom";
import { ButtonRedBig } from "../../../../components/Buttons";
import { ListsReceipts } from "../../../../components/Lists";
import SearchBar from "../../../../components/SearchBar";
import { useEffect, useState } from "react";
import { getReceiptsByCommunity } from "../../../../services/Receipts";
import { useAuth } from "../../../../context/AuthContext";
import { useAxiosWithLoading } from "../../../../services/config/config";

const Receipts = () => {

    const categies = [ "Propietario", "Periodo de Inicio", "Periodo de Fin", "Gestionar"]
    const keys = ['owner_id', 'starting_period', 'end_period'];
    const {community_id} = useParams()
    const {token} = useAuth()
    const navigate = useNavigate();
    const handleClick = (route) => {
      navigate(route);
    };
    const [receipts, setReceipts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const axiosInstance = useAxiosWithLoading();

    const fetchReceipts = async () => {
        const fetchedReceipts = await getReceiptsByCommunity({ token, id: community_id, axiosInstance });
        Array.isArray(fetchedReceipts) ? setReceipts(fetchedReceipts) : setReceipts([])
        Array.isArray(fetchedReceipts) ? setSearchResults(fetchedReceipts) : setSearchResults([])
    };
    useEffect(() => {
        fetchReceipts()
        // eslint-disable-next-line
    },[])

    return(
        <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
                <SearchBar
                    placeholder="Buscar Recibo"
                    data={receipts}
                    searchParams={['owner_id']}
                    onResults={setSearchResults}
                />
                <div className="flex flex-row gap-8">
                    <ButtonRedBig text={"Cargar Recibos por Excel"} onClick={() => handleClick("./new-receipts-excel")}  />
                    <ButtonRedBig text={"Cargar de Forma Manual"}  onClick={() => handleClick("./new-receipts-manual")} />
                </div>
            </div>
            <ListsReceipts categies={categies} keys={keys} items={searchResults} path={"receipts"} buttonText={"Gestionar"} />
        </div>
    )
}

export default Receipts;