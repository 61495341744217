import { useState } from "react"
import { ButtonRedBig } from "../../../../components/Buttons"
import { InputTitle, InputTitleMultiple } from "../../../../components/Inputs"
import { useFieldInputs } from "../../../../hooks/useField"
import { createMultipleOwners } from "../../../../services/User"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "../../../../context/AuthContext"
import { useAxiosWithLoading } from "../../../../services/config/config"

const NewOwnerManual = () => {

  const navigate = useNavigate()
  const { token } = useAuth()
  const { community_id } = useParams()
  const name = useFieldInputs({ title: "Nombre", defaultValue: "" })
  const lastname = useFieldInputs({ title: "Apellidos", defaultValue: "" })
  const nameAccountingAccount = useFieldInputs({ title: "Nombre de Numero de Cuenta", defaultValue: "" })
  const accountingAccount = useFieldInputs({ title: "Numero de Cuenta", defaultValue: 0 })
  const fincaNum = useFieldInputs({ title: "Número de finca", defaultValue: "" })
  const code = useFieldInputs({ title: "Codigo", defaultValue: 0 })
  const group = useFieldInputs({ title: "Grupo", defaultValue: "" })
  const [domiciled, setDomiciled] = useState(false);
  const bankAccount = useFieldInputs({ title: "Cuenta Bancaria", defaultValue: "" })
  const address = useFieldInputs({ title: "Dirección", defaultValue: "" })
  const [emails, setEmails] = useState(['']);
  const [phones, setPhones] = useState(['']);
  const fax = useFieldInputs({ title: "Buzón", defaultValue: "" })
  const handleChangeDomiciled = (e) => setDomiciled(e.target.checked);
  const axiosInstance = useAxiosWithLoading();

  const handleSubmit = () => {
    createMultipleOwners({
      data: [{
        group: group.value,
        code: parseInt(code.value),
        lastName: lastname.value,
        name: name.value,
        accountingAccount: parseInt(accountingAccount.value),
        nameAccountingAccount: nameAccountingAccount.value,
        domiciled: domiciled,
        bankAccount: bankAccount.value,
        address: address.value,
        fincaNum: `Finca ${fincaNum.value}`,
        emails: emails,
        fax: fax.value,
        phones: phones
      }],
      navigate,
      token,
      community_id,
      axiosInstance
    })
  }

  return (
    <div className="flex flex-1 flex-col gap-10">
      <div className="flex flex-col gap-2">
        <p className="text-2xl font-medium">Registra un Nuevo Propietario</p>
        <p className="text-base">Recuerda que todos los datos deben coincidir exactamente igual que se han registrado en el sistema de Universal Properties, de lo contrario no coincidirán los datos a la hora de cargar sus recibos o extractos.</p>
      </div>
      <div className="flex flex-row justify-between flex-wrap gap-8">
        <InputTitle {...name} />
        <InputTitle {...lastname} />
        <InputTitle {...nameAccountingAccount} />
        <InputTitle {...accountingAccount} type={"number"} />
        <InputTitle {...fincaNum} />
        <InputTitle {...code} type={"number"} />
        <InputTitle {...group} />
        <div className="flex flex-row items-center gap-4 w-[48%]">
          <label className="text-base font-semibold">Domiciliado</label>
          <input type="checkbox" checked={domiciled} onChange={handleChangeDomiciled} />
        </div>
        <InputTitle {...bankAccount} />
        <InputTitle {...address} />
        <InputTitleMultiple
          title="Emails"
          values={emails}
          onValuesChange={setEmails}
          type="email"
        />
        <InputTitleMultiple
          title="Teléfonos"
          values={phones}
          onValuesChange={setPhones}
        />
        <InputTitle {...fax} />
      </div>
      <ButtonRedBig text={"Registrar"} onClick={handleSubmit} />
    </div>
  )
}

export default NewOwnerManual;