export const createReceipt = async ({ token, data, navigate, axiosInstance }) => {
    try {
        await axiosInstance.post('/receipt/create', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        navigate("..");
    } catch (error) {
        console.error(error);
    }
};

export const getReceiptsByCommunity = async ({ token, id, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/receipt/list-receipts-community/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const downloadReceipt = async ({ token, data, owner_id, axiosInstance }) => {
    try {
        const response = await axiosInstance.post(`/receipt/download/${owner_id}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer'
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener el PDF:', error);
    }
};

export const getReceiptsByUserId = async ({ token, user_id, community, axiosInstance }) => {
    try {
        const response = await axiosInstance.get(`/receipt/list/${user_id}/${community}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};